import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PostauthService } from '../services/postauth.service';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {

  constructor(private postauth: PostauthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log(request)
    // Start time of the request
    const startTime = Date.now();
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      console.log(event)
      if (event instanceof HttpResponse) {
        console.log(event.body)
    
        if (event.body['responsecode'] === 403) {

          this.postauth.session_expired_subject.next(true);
        }
        // const endTime = Date.now();
        //  const duration = endTime - startTime;
        //   console.log(`Request for ${event.url} took ${duration}ms`)
        //   this.postauth.session_expired_subject.next(true);
      }
    }, (error: any) => {
      // Handle errors
      console.error('Error: ', error)
    }))
    return next.handle(request);
  }
}
