import { Component, OnInit, Optional, ElementRef, AfterViewInit, ModuleWithComponentFactories, ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Series } from 'highcharts';
import * as Highcharts from 'highcharts';
import { OrgData } from 'angular-org-chart/src/app/modules/org-chart/orgData';
import { PostauthService } from 'src/app/services/postauth.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { formatDate, TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';


import Heatmap from 'highcharts/modules/heatmap';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/notification.service';
Heatmap(Highcharts);


@Component({
  selector: 'app-sales-dash-board',
  templateUrl: './sales-dash-board.component.html',
  styleUrls: ['./sales-dash-board.component.scss']
})
export class SalesDashBoardComponent implements OnInit, AfterViewInit {


  data = [];
  selectedItems = [];
  selectedmeasurement = [];
  selectedxaxis = [];
  selectedviewtype = []


  grouped = false;

  hichart2: Chart;
  groupedBy: any;
  graphdata: any = {};
  minDate: Date;
  maxDate: Date;
  seachtext: string;
  charttype = 'line';
  seriesdate: any;
  graphseries: Array<any>;
  currentquery: string = "";
  reportno = 0;
  requestingreport: boolean = false;
  week: Date = new Date()
  month: Date = new Date()
  threeMonth: Date = new Date()
  dateSelect = new FormControl(new Date())
  dateSelected: any;
  public groupedpie: boolean = false;
  public fetchdateagain: boolean = false;

  startdate: FormControl = new FormControl(new Date(), Validators.required);
  enddate: FormControl = new FormControl(new Date(), Validators.required);
  measurementdata = [
    { id: 'cartonquatities', itemName: 'Quantity(Number Of Cartons)' },
    { id: 'ordervalue', itemName: 'Value' },
    { id: 'weight', itemName: 'Net Weight(Tn)' },
    { id: 'uniqueorders', itemName: 'Unique Orders' }
  ];

  durationdata = [
    { id: '', itemName: 'None' },
    { id: this.week, itemName: 'Last Week' },
    { id: this.month, itemName: 'Last Month' },
    { id: this.threeMonth, itemName: 'Last Three Months' }
  ];

  primarydisplaydate = [
    { id: '', itemName: 'None' },
    { id: 'order_date', itemName: 'Date' },
    { id: 'week', itemName: 'Week' },
    { id: 'month', itemName: 'Month' },
  ]
  secondarydisplaydate = [
    { id: '', itemName: 'None' },
    { id: 'order_date', itemName: 'Date' },
    { id: 'week', itemName: 'Week' },
    { id: 'month', itemName: 'Month' },
  ]
  locationdata = [];
  locationNgModel;
  regionsdata = [];
  regionsNgModel;
  categorydata = [];
  categoryNgModel;
  subcategorydata = [];
  subcategoryNgModel;
  manufacturerdata = [];
  manufacturerNgModel;
  branddata = [];
  brandNgModel;
  unitnamedata = [];
  unitnameNgModel;
  yearsdata = [];
  yearsNgModel = [];
  monthdata = [];
  monthNgModel = []
  weekdata = [];
  weekNgModel = []

  selected = {

    filters: {
      category: [],
      location: [],
      subcategory: [],
      region: [],
      productlabel: [],
      unitname: [],
      manufacturer: [],
      year: [],
      month: [],
      week: []
    },
    startdate: this.startdate.value,
    enddate: this.enddate.value,
    groupedby: '',
    viewtype: '',
    measurent: '',
    axiscolumn: ''
  };
  
  filterform: FormGroup;
  filteredlocations: Array<any> = new Array();
  filteredregions: Array<any> = new Array();
  filteredmanufactures: Array<any> = new Array();
  filteredbrand: Array<any> = new Array();

  filteredunit: Array<any> = new Array();
  filteredcategory: Array<any> = new Array();
  filteredsubcategory: Array<any> = new Array();
  filteredyears: Array<any> = new Array();
  filteredmonths: Array<any> = new Array();
  filteredweek: Array<any> = new Array();


  allSelected = false;
  dateRange:Date [];
  @ViewChild('salesdaterange') salesdateRangeElement: ElementRef;

  constructor(private titlecasePipe: TitleCasePipe, private elementRef: ElementRef,
    public postauth: PostauthService, public  notiservice: NotificationService, public route: Router, public toasts: ToastrService) {
    this.week.setDate(this.week.getDate() - 7);
    this.month.setDate(this.month.getDate() - 30);
    this.threeMonth.setDate(this.threeMonth.getDate() - 90);

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();
    this.groupedBy = '';
    this.postauth.viewtitle = 'Sales Report';
    // this.filteredlocations = Array.from(this.postauth.assigned_data['locations']);


    this.simple_chart_config = {
      chart: {
        container: '#tree-simple'
      },

      nodeStructure: {
        text: { name: 'Parent node' },
        children: [
          {
            text: { name: 'First child' }
          },
          {
            text: { name: 'Second child' }
          }
        ]
      }
    };


    this.filterform = new FormGroup({
      location: new FormControl(''),
      region: new FormControl(''),
      brand: new FormControl(''),
      unit: new FormControl(''),
      manufacturer: new FormControl(''),
      category: new FormControl(''),
      subcategory: new FormControl(''),
      year: new FormControl(''),
      month: new FormControl(''),
      week: new FormControl(''),
      duration: new FormControl(''),
      measurent: new FormControl(''),
      locationfilter: new FormControl(''),
      primarydisplay: new FormControl(''),
      secondarydisplay: new FormControl(''),
    });



  }
  orgData: OrgData = {
    name: 'Kapa Oil Rfinary',
    type: 'Company',

    children: [
      {
        name: 'Detergents',
        type: 'VP',
        children: [
          {
            name: 'Toss',
            type: 'manager',
            children: [{
              name: 'Toss Whil', type: 'Toasw', children: [{ name: 'White', type: '15 grams', children: [] },
              { name: 'White', type: '15 grams', children: [] },
              { name: 'White', type: '15 grams', children: [] }]
            }]
          },
          {
            name: 'Jamma',
            type: 'Manager',
            children: []
          }
        ]
      },
      {
        name: 'Black Widow',
        type: 'VP',
        children: [
          {
            name: 'Hulk',
            type: 'manager',
            children: [
              {
                name: 'Spiderman',
                type: 'Intern',
                children: []
              }
            ]
          },
          {
            name: 'Thor',
            type: 'Manager',
            children: [
              {
                name: 'Loki',
                type: 'Team Lead',
                children: []
              }
            ]
          }
        ]
      }
    ]
  };
  private Treant: any;
  simple_chart_config: any;

  chart = new Chart({
    chart: {
      type: 'line'
    },
    yAxis: {
      title: {
        text: 'Number of Employees'
      }
    },

    xAxis: {
      accessibility: {
        rangeDescription: 'Range: 2010 to 2017'


      },
      categories: ['Oils', 'Fats', 'Vegitables']
    }, plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        shadow: false
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: true
    },

    series: [
      {
        type: 'spline',
        name: 'Average',
        data: [3, 2.67, 3, 6.33, 3.33],
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white'
        }
      },
      {
        type: 'column',
        name: 'Line 1',
        data: [[5, 2], [6, 3], [8, 2]],

      },
      {
        type: 'line',
        name: 'Line 2',
        data: [11, 22, 33],

      }
    ], legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  });



  ngAfterViewInit() {


    this.postauth.getAssignedDataKeys().forEach(r => { console.log(r) });
  }



  // add point to chart serie
  add() {
    // john:Option ={}
    // new Chart({chart:{},})
    this.chart.addSeries({
      type: 'pie', name: 'added series', data: [Math.floor(Math.random() * 10), Math.floor(Math.random() * 10),
      Math.floor(Math.random() * 10), Math.floor(Math.random() * 10), Math.floor(Math.random() * 10), Math.floor(Math.random() * 10), Math.floor(Math.random() * 10)]
    }, true, true);
    this.chart.addPoint(Math.floor(Math.random() * 10));
  }

  ngOnInit() {
    this.requestingreport = false;
    this.locationdata = Array.from(this.postauth.assigned_data['location']).map((location, index) => { return { id: location['name'], itemName: location['name'] }; })
    this.regionsdata = Array.from(this.postauth.assigned_data['regions']).map((region, index) => { return { id: region['name'], itemName: region['name'] }; })
    this.categorydata = Array.from(this.postauth.assigned_data['category']).map((category, index) => { return { id: category['name'], itemName: category['name'] }; })
    this.subcategorydata = Array.from(this.postauth.assigned_data['subCategory']).map((subcategory, index) => { return { id: subcategory['name'], itemName: subcategory['name'] }; })
    this.manufacturerdata = Array.from(this.postauth.assigned_data['manufacturer']).map((manufacturer, index) => { return { id: manufacturer['name'], itemName: manufacturer['name'] }; })
    this.branddata = Array.from(this.postauth.assigned_data['products']).map((brand, index) => { return { id: brand['name'], itemName: brand['name'] }; })
    this.unitnamedata = Array.from(this.postauth.assigned_data['sku']).map((unitname, index) => { return { id: unitname['name'], itemName: unitname['name'] }; })
    Array.from(this.postauth.getAssignedDataKeys()).forEach(dataitem => {
      this.primarydisplaydate.push({ id: dataitem, itemName: this.titlecasePipe.transform(dataitem) });
    });

    Array.from(this.postauth.getAssignedDataKeys()).forEach(dataitem => {
      this.secondarydisplaydate.push({ id: dataitem, itemName: this.titlecasePipe.transform(dataitem) });
    });
    this.yearsdata = Array.from(this.postauth.years).map((year, index) => { return { id: year, itemName: year } });
    this.monthdata = Array.from(this.postauth.months).map((month, index) => { return { id: month['num'], itemName: month['name'] } });
    this.weekdata = Array.from(this.postauth.weeks).map((week, index) => { return { id: week, itemName: week } });

  }

  refreshData() {
    this.groupedpie = false;

    this.groupedpie = false;
    if ((this.selectedmeasurement[0]!==undefined)&&(this.selectedmeasurement[0]['id'] === undefined || this.selectedmeasurement[0]['id'] === '')) {

     // this.showNotification('top', 'left', 'Measurent required', 'info')
      this.notiservice.notify(
        "warning",
        "Measurent required"
      );
    }
    if (this.selectedxaxis[0] === undefined||this.selectedxaxis[0]['id'] === undefined || this.selectedxaxis[0]['id'] === '') {

      //this.showNotification('top', 'left', 'Primary Display column required', 'info');
      this.notiservice.notify(
        "warning",
        "Primary Display column required"
      );
    } else {



      this.selected['categorize'] = false;
      this.selected['grouped'] = this.grouped;
      this.selected.axiscolumn = this.selectedxaxis[0]['id'];
      this.selected.measurent = this.selectedmeasurement[0]['id'];
      if (this.selectedviewtype[0] !== undefined) {
        this.selected.viewtype = this.selectedviewtype[0]['id'];
      }
      this.selected.filters = {
        category: this.filteredcategory,
        week: [],
        location: this.filteredlocations,
        manufacturer: this.filteredmanufactures,
        month: [],
        productlabel: this.filteredbrand,
        region: this.filteredregions,
        subcategory: this.filteredsubcategory,
        unitname: this.filteredunit, year: []
      }


      // console.log("this data to middleware  >>> "+JSON.stringify(this.selected))
      this.postauth.fetchData(this.selected, 'sales_report').subscribe(success => {
        this.currentquery = success['query'];
        this.graphdata = success;
        // console.log(JSON.stringify(success))

        if (this.grouped) {
          this.hichart2 = new Chart({
            chart: {
              type: 'bar',
              height: 800
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
            },
            xAxis: { categories: this.graphdata.categories },
            title: {
              text: ''
            },
            credits: {
              enabled: false
            },
            series: [{
              type: undefined,
              data: this.graphdata.series
            }]
          });
        } else {
          this.hichart2 = new Chart({
            chart: {
              type: 'bar',
              height: 800
            },
            yAxis: {
              min: 0,
              title: {
                text: ''
              },
            },
            xAxis: { categories: this.graphdata.categories },
            title: {
              text: ''
            },
            credits: {
              enabled: false
            },
            series: this.graphdata.series
          });
        }
      },
        error => {
          console.log(error);
        });
    }
  }
  enddateFilter = (d: Date | null): boolean => {
    const timediff = ((new Date(this.selected.enddate).getTime() - new Date(this.selected.startdate).getTime()));
    if (timediff > (1000 * 60 * 60 * 24 * 90)) {
      this.notiservice.notify('warning', 'Time range of over of over 6 months not allowed');
      this.selected.enddate = this.selected.startdate;
      return false;
    } else {
      return true;
    }
  }
  // startDateChage(event) {
  //   this.startdate.setValue(event);
  //   // this.selected.startdate = event;
  //   this.selected.startdate = formatDate(event, 'y-MM-dd', 'en-US');
  // }
  // endDateChange(event) {
  //   // this.selected.enddate = event;
  //   // alert('about to set end datre')
  //   this.selected.enddate = formatDate(event, 'y-MM-dd', 'en-US');
  //   this.enddate.setValue(event);
  // }

  dateRangeCreated($event) {  
   
    // if(this.salesdateRangeElement){
    //   this.dateSelected = '';
    // }

    let startDate = $event[0].toJSON().split('T')[0];   
    let endDate = $event[1].toJSON().split('T')[0];    

this.selected.startdate= formatDate(startDate, "y-MM-dd", "en-US") 
this.selected.enddate= formatDate(endDate, "y-MM-dd", "en-US") 
  } 

  startdateFilter = (d: Date | null): boolean => true;


  filterValues(element: string, index, array) {
    console.log(element.toString().toLowerCase().includes(this.seachtext));
    return (element.toString().toLowerCase().includes(this.seachtext));
  }
  selectAllFilter(event, fileld: string) {
    switch (fileld) {
      case 'location': {
        Array.from(this.postauth.assigned_data['location']).forEach(element=>{
         this.filteredlocations.push(element['name'])
        })
       
        break;
      }
      case 'manufacture': {
        Array.from(this.postauth.assigned_data['manufacturer']).forEach(element=>{
          this.filteredmanufactures .push(element['name'])
         })
        break;
      }
      case 'categories': {
        Array.from(this.postauth.assigned_data['category']).forEach(element=>{
          this.filteredcategory .push(element['name'])
         })
        break;
      }
      case 'subCategories': {
        Array.from(this.postauth.assigned_data['subCategory']).forEach(element=>{
          this.filteredsubcategory .push(element['name'])
         })
        break;
      }

      case 'prodcutLabels': {
        Array.from(this.postauth.assigned_data['products']).forEach(element=>{
          this.filteredbrand .push(element['name'])
         })

        break;
      }
      case 'unitNames': {
        Array.from(this.postauth.assigned_data['sku']).forEach(element=>{
          this.filteredunit .push(element['name'])
         })
        break;
      }
      case 'regions': {
        Array.from(this.postauth.assigned_data['regions']).forEach(element=>{
          this.filteredregions .push(element['name'])
         })
        break;
      }
      case 'years': {
        this.filteredyears = this.postauth.years;
        break;
      }
      case 'month': {
        this.filteredmonths = this.postauth.months;
        break;
      }
      case 'week': {
        this.filteredweek = this.postauth.weeks;
        break;
      }
    }
  }
  deselectFilter(event, fileld: string) {
    console.log(event);
    switch (fileld) {
      case 'years': {
        this.filteredyears = this.filteredyears.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'month': {
        this.filteredmonths = this.filteredmonths.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'week': {
        this.filteredweek = this.filteredweek.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'location': {
        this.filteredlocations = this.filteredlocations.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'manufacture': {
        this.filteredmanufactures = this.filteredmanufactures.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'categories': {
        this.filteredcategory = this.filteredcategory.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'subCategories': {
        this.filteredsubcategory = this.filteredsubcategory.filter((item, index) => { return (item !== event['id']); });
        break;
      }
      case 'prodcutLabels': {
        this.filteredbrand = this.filteredbrand.filter((item, index) => { return (item !== event['id']); });

        break;
      }
      case 'unitNames': {
        this.filteredunit = this.filteredunit.filter((item, index) => { return (item !== event['id']); });

        break;
      }
      case 'regions': {
        this.filteredregions = this.filteredregions.filter((item, index) => { return (item !== event['id']); });

        break;
      }
    }
  }
  deselectAllFilter(event, fileld: string) {
    switch (fileld) {
      case 'years': {
        this.filteredyears = [];
        break;
      }
      case 'month': {
        this.filteredmonths = [];
        break;
      }
      case 'week': {
        this.filteredweek = [];
        break;
      }
      case 'location': {
        this.filteredlocations = [];
        break;
      }
      case 'manufacture': {
        this.filteredmanufactures = [];

        break;
      }
      case 'categories': {
        this.filteredcategory = [];
        break;
      }
      case 'subCategories': {
        this.filteredsubcategory = [];
        break;
      }
      case 'prodcutLabels': {
        this.filteredbrand = [];
      }
      case 'unitNames': {
        this.filteredunit = [];
        break;
      }
      case 'regions': {
        this.filteredregions = [];
        break;
      }
    }
  }
  filterFilter(event, fileld: string) {

    console.log(this.filteredlocations);

    if (event['id'] !== '') {
      switch (fileld) {
        case 'location': {
          this.filteredlocations.push(event['id']);
          console.log(this.filteredlocations);
          break;
        }
        case 'manufacture': {
          this.filteredmanufactures.push(event['id']);

          break;
        }
        case 'categories': {
          this.filteredcategory.push(event['id']);

          break;
        }
        case 'subCategories': {
          this.filteredsubcategory.push(event['id']);

          break;
        }
        case 'prodcutLabels': {
          this.filteredbrand.push(event['id']);


          break;
        }
        case 'unitNames': {
          this.filteredunit.push(event['id']);


          break;
        }
        case 'regions': {
          this.filteredregions.push(event['id']);
          break;
        }
        case 'years': {
          this.filteredyears.push(event['id']);
          break;
        }
        case 'month': {
          this.filteredmonths.push(event['id']);
          break;
        }
        case 'week': {
          this.filteredweek.push(event['id']);
          break;
        }
      }
    } else {
      switch (fileld) {
        case 'years': {
          this.filteredyears = this.postauth.years;
          break;
        }
        case 'month': {
          this.filteredmonths = this.postauth.months;
          break;
        }
        case 'week': {
          this.filteredweek = this.postauth.weeks;
          break;
        }
        case 'location': {
          this.filteredlocations = this.postauth.assigned_data['location'];
          break;
        }
        case 'manufacture': {
          this.filteredmanufactures = this.postauth.assigned_data['manufacturer'];
          break;
        }
        case 'categories': {
          this.filteredcategory = this.postauth.assigned_data['category'];
          break;
        }
        case 'subCategories': {
          this.filteredsubcategory = this.postauth.assigned_data['subCategory'];
          break;
        }

        case 'prodcutLabels': {

          this.filteredbrand = this.postauth.assigned_data['products'];

          break;
        }
        case 'unitNames': {
          this.filteredunit = this.postauth.assigned_data['sku'];
          break;
        }
        case 'regions': {
          this.filteredregions = this.postauth.assigned_data['regions'];
          break;
        }
      }

    }
  }
  chartTypeChage(charttype) {
    this.charttype = charttype;
    // if (this.fetchdateagain) {
    //   this.fetchdateagain = false;
    //   this.postauth.fetchData(this.selected, 'sales_report').subscribe(success => {
    //     this.currentquery = success['query'];
    //     this.graphdata = success;
    //     // console.log('Mukii on test3' + JSON.stringify(this.graphdata))

    //     // this.chartTypeChage(charttype);
    //   });
    // } else {
    if (this.graphdata.series !== undefined) {
      if (
        Array.from(this.graphdata.series).length > 1 &&
        charttype === "pie" &&
        !this.grouped
      ) {
        this.notiservice.notify(
          "warning",
          "The Dataset wont generate a valid pie chart"
        );
      } else {
        const piechartdata: Array<{}> = new Array();
        // let graphseries: Array<any>;

        if (this.grouped) {
          this.graphdata.series = this.graphdata.series;
          this.groupedpie = false;

        } else {
          this.groupedpie = false;
          this.graphseries = Array.from(this.graphdata.series).map(seri => {
            if (charttype === 'pie') {
              let recordnumber = 0;
              this.charttype = 'pie';
              Array.from(seri['data']).forEach(dtpoint => {
                piechartdata.push({
                  name: this.graphdata.categories[recordnumber],
                  y: dtpoint
                });
                recordnumber++;
              });
              this.seriesdate = {
                name: 'Brands',
                colorByPoint: true,
                data: piechartdata
              };

            } else {
              this.charttype = charttype;
              //udefenied in grouped data
              if (seri['type'] !== undefined) {
                seri['type'] = charttype;
              }

            }

            return seri;
          });
        }
        if (charttype === 'heatmap') {
          let heatgraphdata: any = {};
          this.selected['categorize'] = false;
          this.selected["grouped"] = this.grouped;
          this.postauth.fetchData(this.selected, "sales_report").subscribe(
            (success) => {
              heatgraphdata = success

              let seriesArray: Array<Array<number>> = new Array();
              let y_Axis: Array<any> = new Array();
              for (var x = 0; x < heatgraphdata.categories.length; x++) {
                for (var d = 0; d < heatgraphdata.series.length; d++) {
                  seriesArray.push([x, d, heatgraphdata.series[d].data[x]]);
                  if (x === 0) {
                    y_Axis.push(heatgraphdata.series[d].name);
                  }
                }

              }
              // console.log(seriesArray)
              this.hichart2 = new Chart({

                chart: {
                  type: 'heatmap',
                  marginTop: 40,
                  marginBottom: 80
                },
                title: {
                  text: ''
                },
                xAxis: {
                  categories: heatgraphdata.categories
                },
                yAxis: {
                  categories: y_Axis,
                  title: null
                },
                colorAxis: {
                  min: 0,
                  minColor: '#FFFFFF',
                  maxColor: Highcharts.getOptions().colors[0]
                },
                legend: {
                  align: 'right',
                  layout: 'vertical',
                  margin: 0,
                  verticalAlign: 'top',
                  y: 25,
                  symbolHeight: 280
                },
                tooltip: {
                  formatter: function () {
                    return '<b>' + this.point.value +
                      '</b> on <br><b>' +
                      this.series.xAxis.categories[this.point.x] +
                      '</b> in <br><b>' +
                      this.series.yAxis.categories[this.point.y] + '</b>';
                  }
                },
                series: [{
                  name: '',
                  type: undefined,
                  borderWidth: 1,
                  data: seriesArray,

                  dataLabels: {
                    enabled: true,
                    color: '#000000'
                  }
                }]

              });

            })

        }
        if (charttype === 'pie') {
          if (this.grouped) {
            this.groupedpie = true;
            this.data = [];
            var uniqsel = this.selected;
            uniqsel['grouped'] = false;
            this.postauth.fetchData(uniqsel, 'sales_report').subscribe(success => {
              this.currentquery = success['query'];
              let piedata: any = {};
              piedata = success;
              this.fetchdateagain = true;
              for (var arraypos = 0; arraypos < Array.from(piedata.categories).length; arraypos++) {
                piedata.categories[arraypos];
                var parentid = (arraypos + 1);
                this.data.push(
                  {
                    id: parentid + '',
                    parent: '0.0',
                    name: piedata.categories[arraypos]
                  }
                )
                var chileid: number = parentid + 0.1;
                Array.from(piedata.series).forEach(serie => {
                  chileid = chileid + 0.1;
                  this.data.push(
                    {
                      id: Number(chileid).toFixed(1) + '',
                      parent: parentid + '',
                      name: serie['name'],
                      value: serie['data'][arraypos]
                    }
                  )

                })

              }

              // console.log(this.data)
              if (this.data.length > 2500) {
                this.notiservice.notify('warning', 'Large Dataset ' + this.data.length + ' to generate grouped pie chart');
              } else {
                let newoptions: any = {
                  chart: {
                    height: '100%'
                  },

                  title: {
                    text: ''
                  },
                  subtitle: {
                    text: ''
                  },
                  plotOptions: {
                    series: {
                      cursor: 'pointer',
                      events: {
                        click: (event) => this.show(event)
                      }
                    }
                  },
                  series: [{
                    type: "sunburst",
                    data: this.data,
                    allowDrillToNode: true,
                    cursor: 'pointer',
                    dataLabels: {
                      format: '{point.name}',
                      filter: {
                        property: 'innerArcLength',
                        operator: '>',
                        value: 16
                      },
                      rotationMode: 'circular'
                    },
                    levels: [{
                      level: 1,
                      levelIsConstant: false,
                      dataLabels: {
                        filter: {
                          property: 'outerArcLength',
                          operator: '>',
                          value: 64
                        }
                      }
                    }, {
                      level: 2,
                      colorByPoint: true
                    },
                    {
                      level: 3,
                      colorVariation: {
                        key: 'brightness',
                        to: -0.5
                      }
                    }, {
                      level: 4,
                      colorVariation: {
                        key: 'brightness',
                        to: 0.5
                      }
                    }]

                  }],
                  tooltip: {
                    headerFormat: "",
                    pointFormat: '<b>{point.name}</b> is <b>{point.value}</b>'
                  }


                }
                Highcharts.chart('groupedpiechart', newoptions)
              }
            })



          } else {
            this.groupedpie = false;

            this.hichart2 = new Chart({
              chart: {
                type: 'pie',
                height: 800
              },

              xAxis: { categories: this.graphdata.categories },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              series: [{ type: 'pie', data: piechartdata }]
            });
          }
        } else {
          if (this.graphseries === undefined) {
            this.selected["grouped"] = !this.grouped;
            this.postauth.fetchData(this.selected, "sales_report").subscribe(
              (success) => {
                let graphdatanew: any = {};
                graphdatanew = success

                this.groupedpie = false;
                this.hichart2 = new Chart({
                  chart: {
                    type: this.charttype,
                    height: 800
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: this.graphdata.yAxisTitle
                    },
                  },
                  xAxis: { categories: this.graphdata.categories },
                  title: {
                    text: ''
                  },
                  credits: {
                    enabled: false
                  },
                  // series: [{
                  //   type: undefined,
                  //   data: this.graphdata.series  
                  // }]
                  series: graphdatanew.series
                });
              })
          } else {
            this.groupedpie = false;
            this.hichart2 = new Chart({
              chart: {
                type: this.charttype,
                height: 800
              },
              yAxis: {
                min: 0,
                title: {
                  text: this.graphdata.yAxisTitle
                },
              },
              xAxis: { categories: this.graphdata.categories },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              // series: [{
              //   type: undefined,
              //   data: this.graphdata.series  
              // }]
              series: this.graphseries
            });
          }

        }
      }
    } else {
      this.notiservice.notify('warning', 'No Data to generate chart');
    }
    // }
  }
  toggle(e) {
    this.grouped = e.target.checked;
    this.refreshData()

  }
  setDatepicker(event) {
    //alert(val)
    // console.log(event)

    if (this.week == event) {
      this.dateSelect = new FormControl(this.week)
      this.selected.startdate = this.dateSelect.value;
      this.selected.enddate =new Date();
      this.dateRange=[this.selected.startdate,this.selected.enddate];


    } else if (this.month == event) {
      this.dateSelect = new FormControl(this.month)
      this.selected.startdate = this.dateSelect.value;
      this.selected.enddate =new Date();
      this.dateRange=[this.selected.startdate,this.selected.enddate];

    } else if (this.threeMonth == event) {
      this.dateSelect = new FormControl(this.threeMonth)
      this.selected.startdate = this.dateSelect.value;
      this.selected.enddate =new Date();
      this.dateRange=[this.selected.startdate,this.selected.enddate];

    } else {
      this.dateSelect = null
    }

  }

  show(event) {

  }

  selectAll(field: string) {
    this.allSelected = !this.allSelected;  // to control select-unselect

    if (this.allSelected) {

      switch (field) {
        case "location": {
          //   this.selectAllLocation.options.forEach((item) => item.select());
          // this.selectAllLocation.close();
          break;
        }
        case "manufacture": {
          //   this.selectAllManufacturer.options.forEach((item) => item.select());
          // this.selectAllManufacturer.close();
          break;

        }
        case "categories": {
          //   this.selectAllCategory.options.forEach((item) => item.select());
          // this.selectAllCategory.close();
          break;

        }
        case "subCategories": {
          //  this.selectAllSubcategory.options.forEach((item) => item.select());
          // this.selectAllSubcategory.close();
          break;

        }
        case "prodcutLabels": {
          // this.selectAllProduct.options.forEach((item) => item.select());
          // this.selectAllProduct.close();
          break;

        }
        case "unitNames": {
          //  this.selectAllSku.options.forEach((item) => item.select());
          // this.selectAllSku.close();
          break;

        }
        case "regions": {
          //  this.selectAllRegion.options.forEach((item) => item.select());
          // this.selectAllRegion.close();
          break;

        }

      }
    }

    else {

      switch (field) {
        case "location": {
          // this.selectAllLocation.options.forEach((item) => { item.deselect() });
          // this.selectAllLocation.close();
          break;
        }
        case "manufacture": {
          // this.selectAllManufacturer.options.forEach((item) => { item.deselect() });
          // this.selectAllManufacturer.close();
          break;

        }
        case "categories": {
          //  this.selectAllCategory.options.forEach((item) => { item.deselect() });
          //this.selectAllCategory.close();
          break;

        }
        case "subCategories": {
          // this.selectAllSubcategory.options.forEach((item) => { item.deselect() });
          // this.selectAllSubcategory.close();
          break;

        }
        case "prodcutLabels": {
          //  this.selectAllProduct.options.forEach((item) => { item.deselect() });
          //  this.selectAllProduct.close();
          break;

        }
        case "unitNames": {
          // this.selectAllSku.options.forEach((item) => { item.deselect() });
          // this.selectAllSku.close();
          break;

        }
        case "regions": {
          //this.selectAllRegion.options.forEach((item) => { item.deselect() });
          // this.selectAllRegion.close();
          break;

        }

      }

    }
  }
  showNotification(start, pos, message, title) {
    this.toasts.info(message, title);
  }

}



