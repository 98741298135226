import { Injectable } from '@angular/core';
import { PostauthService } from './postauth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { subscribe_detail } from '../modules/preauth/components/register/register.component';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreAuthService {
  redirectUrl:String;
  userSessionActive = new Subject<boolean>();


  constructor(public postauth: PostauthService, public http: HttpClient) {
    this.userSessionActive.next(false);
  }
  
  authenticate(username: string, password: string,code:string) {
    this.postauth.lastnetworkcall=Date.now();
    return this.http.post(this.postauth.remoteUrl + '/authenticate',
      { username:username, password:password,code:code})

  }
  subscribe(registerdetail:subscribe_detail){
    return this.http.post(this.postauth.remoteUrl + '/subscribe', registerdetail)
  }

  setUserSessionActive(userSessionActive: boolean){
      this.userSessionActive.next(userSessionActive);
  }
  getUserSessionActive():Observable<boolean>{
     return this.userSessionActive.asObservable();
  }
}
