import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route, UrlSegment, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { PostauthService } from '../services/postauth.service';


@Injectable({
  providedIn: 'root'
})
export class LogutGuardGuard implements CanActivate , CanLoad {
  constructor(private postauth: PostauthService,private router: Router){
     }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.logOut(state.url);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      // alert('nmooo')
      return this.logOut(route.path);
    }

  logOut(url: string): boolean {
  //  alert('nmooo')
      localStorage.removeItem('User');
      localStorage.removeItem('Data');
      this.postauth.clearPostauthData();  
     
    return true;
  
   
   
  }
  
}
