import { Component, OnInit, Optional, ElementRef, AfterViewInit, ModuleWithComponentFactories } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Series } from 'highcharts';
import * as Highcharts from 'highcharts';
import { OrgData } from 'angular-org-chart/src/app/modules/org-chart/orgData';
import { PostauthService } from 'src/app/services/postauth.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { formatDate } from '@angular/common';
import GroupedCategories from 'highcharts-grouped-categories/grouped-categories';
import Heatmap from 'highcharts/modules/heatmap.js';
Heatmap(Highcharts);


GroupedCategories(Highcharts);


@Component({
  selector: 'app-grouped',
  templateUrl: './grouped.component.html',
  styleUrls: ['./grouped.component.scss']
})
export class GroupedComponent implements OnInit , AfterViewInit {


  testData:any = [[0, 0, 10], [0, 1, 19], [0, 2, 8], [0, 3, 24], [0, 4, 67],
  [1, 0, 92], [1, 1, 58], [1, 2, 78], [1, 3, 117], [1, 4, 48],
  [2, 0, 35], [2, 1, 15], [2, 2, 123], [2, 3, 64], [2, 4, 52],
  [3, 0, 72], [3, 1, 132], [3, 2, 114], [3, 3, 19], [3, 4, 16],
  [4, 0, 38], [4, 1, 5], [4, 2, 8], [4, 3, 117], [4, 4, 115],
  [5, 0, 88], [5, 1, 32], [5, 2, 12], [5, 3, 6], [5, 4, 120],
  [6, 0, 13], [6, 1, 44], [6, 2, 88], [6, 3, 98], [6, 4, 96],
  [7, 0, 31], [7, 1, 1], [7, 2, 82], [7, 3, 32], [7, 4, 30],
  [8, 0, 85], [8, 1, 97], [8, 2, 123], [8, 3, 64], [8, 4, 84],
  [9, 0, 47], [9, 1, 114], [9, 2, 31], [9, 3, 48], [9, 4, 91]];
  grouped = true;


  hichart: Chart;
  hichart2: Chart;
  hichart3: Chart;
  groupedBy: any;
  graphdata: any = {};
  minDate: Date;
  maxDate: Date;
  seachtext: string;
  charttype = 'line';
  currentquery: string = "";
  reportno=0;
  requestingreport:boolean=false;

  startdate: FormControl = new FormControl(new Date(), Validators.required);
  enddate: FormControl = new FormControl(new Date(), Validators.required);
  selected = {
    filters: {
      category: '',
      location: '',
      subcategory: '',
      region: '',
      productlabel: '',
      unitname: '',
      manufacturer: '',
      year: '',
      month: '',
      week: ''
    },
    startdate: this.startdate.value,
    enddate: this.enddate.value,
    groupedby: '',
    viewtype: '',
    measurent: '',
    axiscolumn: ''
  };
  filterform: FormGroup;
  filteredlocations: Array<any> = new Array();
  filteredregions: Array<any> = new Array();
  filteredmanufactures: Array<any> = new Array();
  filteredbrand: Array<any> = new Array();

  filteredunit: Array<any> = new Array();
  filteredcategory: Array<any> = new Array();
  filteredsubcategory: Array<any> = new Array();


  constructor(private elementRef: ElementRef,
    public postauth: PostauthService, public notiservice: NotifierService) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();
    this.groupedBy = '';
    this.postauth.viewtitle='Grouped Sales Report';
    // this.filteredlocations = Array.from(this.postauth.assigned_data['locations']);
  
    this.hichart = new Chart({
      chart: {
        type: 'line'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{ type: 'line', data: [1, 3, 4] }

      ]
    });

    this.simple_chart_config = {
      chart: {
        container: '#tree-simple'
      },

      nodeStructure: {
        text: { name: 'Parent node' },
        children: [
          {
            text: { name: 'First child' }
          },
          {
            text: { name: 'Second child' }
          }
        ]
      }
    };


    this.filterform = new FormGroup({
      location: new FormControl(''),
      region: new FormControl(''),
      brand: new FormControl(''),
      unit: new FormControl(''),
      manufacturer: new FormControl(''),
      category: new FormControl(''),
      subcategory: new FormControl(''),
      year: new FormControl(''),
      month: new FormControl(''),
      week: new FormControl(''),
      locationfilter: new FormControl('')
    });



  }
  orgData: OrgData = {
    name: 'Kapa Oil Rfinary',
    type: 'Company',

    children: [
      {
        name: 'Detergents',
        type: 'VP',
        children: [
          {
            name: 'Toss',
            type: 'manager',
            children: [{
              name: 'Toss Whil', type: 'Toasw', children: [{ name: 'White', type: '15 grams', children: [] },
              { name: 'White', type: '15 grams', children: [] },
              { name: 'White', type: '15 grams', children: [] }]
            }]
          },
          {
            name: 'Jamma',
            type: 'Manager',
            children: []
          }
        ]
      },
      {
        name: 'Black Widow',
        type: 'VP',
        children: [
          {
            name: 'Hulk',
            type: 'manager',
            children: [
              {
                name: 'Spiderman',
                type: 'Intern',
                children: []
              }
            ]
          },
          {
            name: 'Thor',
            type: 'Manager',
            children: [
              {
                name: 'Loki',
                type: 'Team Lead',
                children: []
              }
            ]
          }
        ]
      }
    ]
  };
  private Treant: any;
  simple_chart_config: any;

  chart = new Chart({
    chart: {
      type: 'line'
    },
    yAxis: {
      title: {
        text: 'Number of Employees'
      }
    },

    xAxis: {
      accessibility: {
        rangeDescription: 'Range: 2010 to 2017'


      },
      categories: ['Oils', 'Fats', 'Vegitables']
    }, plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        shadow: false
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: true
    },

    series: [
      {
        type: 'spline',
        name: 'Average',
        data: [3, 2.67, 3, 6.33, 3.33],
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: 'white'
        }
      },
      {
        type: 'column',
        name: 'Line 1',
        data: [[5, 2], [6, 3], [8, 2]],

      },
      {
        type: 'line',
        name: 'Line 2',
        data: [11, 22, 33],

      }
    ], legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  });



  ngAfterViewInit() {
 
    this.elementRef.nativeElement
      .addEventListener('click', this.onClick.bind(this));
  }

  onClick(event) {
    console.log(event);
  }

  // add point to chart serie
  add() {
    // john:Option ={}
    // new Chart({chart:{},})
    this.chart.addSeries({
      type: 'pie', name: 'added series', data: [Math.floor(Math.random() * 10), Math.floor(Math.random() * 10),
      Math.floor(Math.random() * 10), Math.floor(Math.random() * 10), Math.floor(Math.random() * 10), Math.floor(Math.random() * 10), Math.floor(Math.random() * 10)]
    }, true, true);
    this.chart.addPoint(Math.floor(Math.random() * 10));
  }

  ngOnInit() {
this.requestingreport=false;
  }

    refreshData() {
  
      if (this.selected.measurent === undefined || this.selected.measurent === '') {
        this.notiservice.notify('warning', 'Measurent required');
      }
      if (this.selected.axiscolumn === undefined || this.selected.axiscolumn === '') {
        this.notiservice.notify('warning', 'Primary Display column required');
      } else {
        this.postauth.fetchData(this.selected, 'sales_report').subscribe(success => {
          this.currentquery=success['query'];
          this.graphdata = success;
          this.hichart2 = new Chart({
        
            chart : {
              type: 'heatmap',
              marginTop: 40,
              marginBottom: 80
           },
           title : {
              text: 'Sales per employee per weekday'   
           },
           xAxis : {
              categories: ['Alexander', 'Marie', 'Maximilian', 'Sophia', 'Lukas',
                 'Maria', 'Leon', 'Anna', 'Tim', 'Laura']
           },
           yAxis : {
              categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                 title: null
           },
           colorAxis : {
              min: 0,
              minColor: '#FFFFFF',
              maxColor: Highcharts.getOptions().colors[0]
           },
           legend : {
              align: 'right',
              layout: 'vertical',
              margin: 0,
              verticalAlign: 'top',
              y: 25,
              symbolHeight: 280
           },
           tooltip : {
              formatter: function () {
                 return '<b>' + this.series.xAxis.categories[this.point.x] +
                    '</b> sold <br><b>' +
                    this.point.value +
                    '</b> items on <br><b>' +
                    this.series.yAxis.categories[this.point.y] + '</b>';
              }
           },
           series : [{
              name: 'Sales per employee',
              type:undefined,
              borderWidth: 1,
              data:this.testData, 
              
              dataLabels: {
                 enabled: true,
                 color: '#000000'
              } 
           }]
          });
        },
          error => {
            console.log(error);
          });
      }
    }
    enddateFilter = (d: Date | null): boolean => {
      const timediff = ((new Date(this.selected.enddate).getTime() - new Date(this.selected.startdate).getTime()));
      if (timediff > (1000 * 60 * 60 * 24 * 90)) {
        this.notiservice.notify('error', 'Time range of over of over 6 months not allowed');
        this.selected.enddate = this.selected.startdate;
        return false;
      } else {
        return true;
      }
    }
    startDateChage(event) {
      this.startdate.setValue(event);
      // this.selected.startdate = event;
      this.selected.startdate = formatDate(event,'y-MM-dd','en-US');
    }
    endDateChange(event) {
      // this.selected.enddate = event;
      this.selected.enddate = formatDate(event,'y-MM-dd','en-US');
      this.enddate.setValue(event);
    }
  
  startdateFilter = (d: Date | null): boolean => true;


  filterValues(element: string, index, array) {
    console.log(element.toString().toLowerCase().includes(this.seachtext));
    return (element.toString().toLowerCase().includes(this.seachtext));
  }

  filterFilter(event, fileld: string) {
console.log(event);
    if (event !== '') {
      switch (fileld) {
        case 'location': {
          this.filteredlocations = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data['location']).forEach(element => {
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredlocations.push(element);
            }
          });
          break;
        }
        case 'manufacture': {
          this.filteredmanufactures = [];
          this.seachtext = event;

          // tslint:disable-next-line: no-string-literal

          Array.from(this.postauth.assigned_data['manufacturer']).forEach(element => {
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredmanufactures.push(element);
            }
          });
          break;
        }
        case 'categories': {
          this.filteredcategory = [];
          this.seachtext = event;

          // tslint:disable-next-line: no-string-literal
          Array.from(this.postauth.assigned_data['category']).forEach(element => {
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredcategory.push(element);
            }
          });
          break;
        }
        case 'subCategories': {
          this.filteredsubcategory = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data['subCategory']).forEach(element => {
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredsubcategory.push(element);
            }
          });
          break;
        }
        case 'prodcutLabels': {
          this.filteredbrand = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data['products']).forEach(element => {
            console.log(element);
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredbrand.push(element);
            }
          });
          break;
        }
        case 'unitNames': {
          this.filteredunit = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data['sku']).forEach(element => {
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredunit.push(element);
            }
          });
          break;
        }
        case 'regions': {
          this.filteredregions = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data['regions']).forEach(element => {
            if (element['name'].toString().toLowerCase().includes(this.seachtext)) {
              this.filteredregions.push(element);
            }
          });
          break;
        }
      }
    } else {
      switch (fileld) {
        case 'location': {
          this.filteredlocations = this.postauth.assigned_data['location'];
          break;
        }
        case 'manufacture': {
          this.filteredmanufactures = this.postauth.assigned_data['manufacturer'];
          break;
        }
        case 'categories': {
          this.filteredcategory = this.postauth.assigned_data['category'];
          break;
        }
        case 'subCategories': {
          this.filteredsubcategory = this.postauth.assigned_data['subCategory'];
          break;
        }
        
        case 'prodcutLabels': {
   
          this.filteredbrand = this.postauth.assigned_data['products'];

          break;
        }
        case 'unitNames': {
          this.filteredunit = this.postauth.assigned_data['sku'];
          break;
        }
        case 'regions': {
          this.filteredregions = this.postauth.assigned_data['regions'];
          break;
        }
      }

    }
  }
  chartTypeChage(charttype) {
    let seriesdate: any;
    if (Array.from(this.graphdata.series).length > 1 && charttype === 'pie') {
      this.notiservice.notify('warning', 'The Dataset wont generate a valid pie chart');
    } else {
      const piechartdata: Array<{}> = new Array();
      let graphseries: Array<any> = Array.from(this.graphdata.series).map(seri => {
        if (charttype === 'pie') {
          let recordnumber = 0;
          this.charttype = 'pie';
          Array.from(seri['data']).forEach(dtpoint => {
            piechartdata.push({
              name: this.graphdata.categories[recordnumber],
              y: dtpoint
            });
            recordnumber++;
          });
          seriesdate = {
            name: 'Brands',
            colorByPoint: true,
            data: piechartdata
          };

        } else {
          this.charttype = charttype;
          seri['type'] = charttype;
        }

        return seri;
      });
      if (charttype === 'pie') {
        graphseries = seriesdate;
        this.hichart2 = new Chart({
          chart: {
            type: 'pie',
            height: 800
          },

          xAxis: { categories: this.graphdata.categories },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          series: [{ type: 'pie', data: piechartdata }]
        });
      } else {
        this.hichart2 = new Chart({
          chart: {
            type: this.charttype,
            height: 800
          },
          yAxis: {
            min: 0,
            title: {
              text: this.graphdata.yAxisTitle
            },
          },
          xAxis: this.testData,
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          series: [{
            type: undefined,
            data: [4, 14, 18, 5, 6, 5, 14, 15, 18]
        }],
        });
      }
    }
  }

  // toggle(e){
  //   if (this.selected.measurent === undefined || this.selected.measurent === '') {
  //     this.notiservice.notify('warning', 'Measurent required');
  //   }
  //   if (this.selected.axiscolumn === undefined || this.selected.axiscolumn === '') {
  //     this.notiservice.notify('warning', 'Primary Display column required');
  //   }
  //   this.grouped = e.target.checked;
   
  //   if (this.grouped) {
  //     this.postauth.fetchData(this.selected, 'sales_report').subscribe(success => {
  //       this.currentquery=success['query'];
  //       this.graphdata = success;
  //       this.hichart2 = new Chart({
  //         chart: {
  //           type: 'bar',
  //           height: 800
  //         },
  //         yAxis: {
  //           min: 0,
  //           title: {
  //             text: ''
  //           },
  //         },
  //         xAxis: this.testData,
  //         // xAxis: { categories: this.graphdata.categories },
  //         title: {
  //           text: ''
  //         },
  //         credits: {
  //           enabled: false
  //         },
  //         // series: this.graphdata.series
  //         series: [{
  //           type: undefined,
  //           data: [4, 14, 18, 5, 6, 5, 14, 15, 18]
  //       }],
  //       });
  //     });
  // }else{

  //   this.postauth.fetchData(this.selected, 'sales_report').subscribe(success => {
  //     this.currentquery=success['query'];
  //     this.graphdata = success;
  //     this.hichart2 = new Chart({
  //       chart: {
  //         type: 'bar',
  //         height: 800
  //       },
  //       yAxis: {
  //         min: 0,
  //         title: {
  //           text: ''
  //         },
  //       },
  //       // xAxis: this.testData,
  //       xAxis: { categories: this.graphdata.categories },
  //       title: {
  //         text: ''
  //       },
  //       credits: {
  //         enabled: false
  //       },
  //       series: this.graphdata.series
  //       // series: [{
  //       //   type: undefined,
  //       //   data: [4, 14, 18, 5, 6, 5, 14, 15, 18]
  //     // }],
  //     });
  //   },
  //     error => {
  //       console.log(error);
  //     });
  
  //   console.log("mukii unchecked")
    
  // }

  // }
}






