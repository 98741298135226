
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SalesDashBoardComponent } from './components/sales-dash-board/sales-dash-board.component';
import { PricingDashBoardComponent } from './components/pricing-dash-board/pricing-dash-board.component';
import { ReportstemplateComponent } from 'src/app/layouts/reportstemplate/reportstemplate.component';
import { PowerbiresolverService } from 'src/app/layouts/reportstemplate/powerbiresolver.service';
import { StockingDashBoardComponent } from './components/stocking-dash-board/stocking-dash-board.component';
import { StockDashBoardComponent } from './components/stock-dash-board/stock-dash-board.component';
import { GroupedComponent } from './components/grouped/grouped.component';
import { GoogleheatmapComponent } from './components/googleheatmap/googleheatmap.component';

export const NewReportroutes: Routes = [
  {
    path: '',
    runGuardsAndResolvers:'always',
    children: [

      { path: 'sales', component: SalesDashBoardComponent },
      { path: 'pricing', component: PricingDashBoardComponent },
      {
        path: 'powerbi/:datasetId/:reportId',component: ReportstemplateComponent,
        resolve: { Requestembedtoken: PowerbiresolverService},
        runGuardsAndResolvers:'always'
      },
      { path: 'power_dashboard', component: SalesDashBoardComponent },
      { path: '', component: SalesDashBoardComponent },
      { path: 'stock', component: StockingDashBoardComponent },
      { path: 'stock2', component: StockDashBoardComponent },
      { path: 'stock3', component: GroupedComponent },
      { path: 'stock4', component: GoogleheatmapComponent }

    
    ]
    
  }];

