import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { PostauthService } from 'src/app/services/postauth.service';
// declare const $: any;
// declare interface RouteInfo {
//   path: string;
//   title: string;
//   type?: string;
//   icontype?: string;
//   rtlTitle?: string;
//   collapse?: string;
//   isCollapsed?: boolean;
//   isCollapsing?: any;  
// }


// export const ROUTES: RouteInfo[] = [
//   { path: 'sales', title: 'Sale',  icontype: '"tim-icons icon-image-02",' },
//   { path: 'pricing', title: 'Price',  icontype: 'money' },
//  // {path: 'salesgoogleheatmap', title: 'HeatMap', icon:'analytics',class:''}
//   //{ path: 'grouped', title: 'group', icon: 'merge_type', class: '' },


// ];

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}
export interface PowerBiRouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
  datasetid?:string,
  reportid?:string
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard/powerbi",
    title: "Home",
    type: "link",
    icontype: "tim-icons icon-image-02",
    rtlTitle: "صفحات",

  },

  {
    path: "/reports",
    title: "Reports",
    type: "sub",
    icontype: "tim-icons tim-icons icon-notes",
    collapse: "pages",
    rtlTitle: "صفحات",
    isCollapsed: false,
    children: [
      {
        path: "sales",
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: "Sales",
        type: "link",
        smallTitle: "S"
      },
      {
        path: "pricing",
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: "Pricing",
        type: "link",
        smallTitle: "P"
      }
    ]
  }

];
export const POWERBIROUTES: PowerBiRouteInfo[] = [


  {
    path: "/reports",
    title: "PowerBi",
    type: "sub",
    icontype: "tim-icons  icon-single-copy-04",
    collapse: "pages",
    rtlTitle: "صفحات",
    isCollapsed: false,
    children: [
     
    ]
  }

];

@Component({
  selector: 'app-report-sidebar',
  templateUrl: './report-sidebar.component.html',
  styleUrls: ['./report-sidebar.component.scss']
})

export class ReportSidebarComponent implements OnInit {
  menuItems: any[];
  powerbimenuItems: any[];
  powerbidastboads: any[];

  constructor(private element: ElementRef, public router: Router, public postauth: PostauthService) {
  }



  ngOnInit() {


    
    this.powerbidastboads = new Array();

    Array.from(this.postauth.currentUser.reportList).forEach(report => {

     this.powerbidastboads.push({
        path:'powerbi',        
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: report['name'],
        type: "link",
        smallTitle: "S",
        datasetid:report['datasetid'],
        id:report['id']
      });

    })
    POWERBIROUTES[0].children=this.powerbidastboads;
    

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.powerbimenuItems = POWERBIROUTES.filter(powerbimenuItems => powerbimenuItems);
  }

}
