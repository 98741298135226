import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-layout',
  templateUrl: './report-layout.component.html',
  styleUrls: ['./report-layout.component.scss']
})
export class ReportLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  minimizeSidebar() {
   
  }

}
