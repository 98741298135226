import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { PostauthService } from 'src/app/services/postauth.service';
import { Requestembedtoken } from '../dashboardpowerbi/dashboardpowerbi.component';
import * as pbi from 'powerbi-client';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-reportstemplate',
  templateUrl: './reportstemplate.component.html',
  styleUrls: ['./reportstemplate.component.scss']
})
export class ReportstemplateComponent implements OnInit {

  token: {};
  embedconfig: {}
  reportDetails: any;

  reportconfigs: Array<Requestembedtoken> = new Array();
  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef
  time_expiration: string;
  powerbi: pbi.service.Service;
  dataResolved:boolean=false

  constructor(public router: Router, public postauth: PostauthService, public activatedroute: ActivatedRoute) {
   
   this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    this.getpowerbireportconfigs()
  

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
   this.showReport();
    });
 
  
  }


  getpowerbireportconfigs() {
    this.activatedroute.data.subscribe(data => {
      console.log(data.Requestembedtoken)
      // this.reportDetails = data.Requestembedtoken
      this.token = data.Requestembedtoken['embedToken']
      this.embedconfig = data.Requestembedtoken['embedConfig']
    })
  }


  ngAfterViewInit() {
   this.showReport()
  }

  refreshPowerBiReports(expiration, minutesToRefresh = 1) {
    var x = setInterval(() => {
      let date = formatDate(expiration, 'y-MM-dd HH:mm:ss', 'en-US')
      let refreshdiff = new Date(date).getTime() - new Date().getTime()
      let minute = Math.floor((refreshdiff % (1000 * 60 * 60)) / (1000 * 60))
      //console.log(minute)
      if (refreshdiff < 1000 * 60 * minutesToRefresh) {

        clearInterval(x);
        this.updateEmbedToken()
      }
    })


  }





  showReport() {
    // Embed URL    
    let embedtoken = this.token;
    //  let expiration =this.token;
    let embedUrl = environment.powerBI.reportBaseURL;
    let embedReportId = this.embedconfig;
    console.log(embedtoken['token'])
    console.log(embedReportId['reportId'])
    let settings: pbi.IEmbedSettings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: true,
    };
    let config: pbi.IEmbedConfiguration = {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: embedtoken['token'],
      embedUrl: embedUrl,
      id: embedReportId['reportId'],
      filters: [],
      settings: settings,

    };
    console.log(config);
    let reportContainer = this.reportContainer.nativeElement;
    // let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        let report = this.powerbi.embed(reportContainer, config);
    //  alert("before")
    report.off("loaded");
    
    report.on("loaded", () => {
      // alert("Loaded");
      
      this.refreshPowerBiReports(embedtoken['expiration'], 1);
    });
    report.on("error", () => {
      console.log("Error");

    });
  }
  updateEmbedToken() {
    let embedReportdetail: Requestembedtoken = { reportid: this.embedconfig['reportId'], datasetid: this.embedconfig['datasetId'] }
    this.postauth.updatePowerBiReportconfigs(embedReportdetail).subscribe(su => {
      console.log(su)

      this.token = su['embedToken']
      this.embedconfig = su['embedConfig']
      this.showReport()
    })
  }


}
