import { async } from '@angular/core/testing';
import { element } from 'protractor';
import { GroupedCategories } from 'highcharts-grouped-categories/grouped-categories';
import {
  Component,
  OnInit,
  Optional,
  ElementRef,
  AfterViewInit,
  ModuleWithComponentFactories,
  Input,
  ViewChild,
} from "@angular/core";
import { Chart } from "angular-highcharts";
import * as Highcharts from "highcharts";
import { OrgData } from "angular-org-chart/src/app/modules/org-chart/orgData";
import { PostauthService } from "src/app/services/postauth.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { formatDate } from "@angular/common";
import HC_sunburst from 'highcharts/modules/sunburst';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core/option';
HC_sunburst(Highcharts);
import Heatmap from 'highcharts/modules/heatmap.js';
Heatmap(Highcharts);

declare const google: any;

@Component({
  selector: 'app-googleheatmap',
  templateUrl: './googleheatmap.component.html',
  styleUrls: ['./googleheatmap.component.scss']
})
export class GoogleheatmapComponent implements OnInit {
  selectedarrayposition:number=0;
  data = [{
    id: '0.0',
    parent: '',
    name: 'The World'
  }, {
    id: '1.3',
    parent: '0.0',
    name: 'Asia'
  }, {
    id: '1.1',
    parent: '0.0',
    name: 'Africa'
  }, {
    id: '1.2',
    parent: '0.0',
    name: 'America'
  }, {
    id: '1.4',
    parent: '0.0',
    name: 'Europe'
  }, {
    id: '1.5',
    parent: '0.0',
    name: 'Oceanic'
  },

  /* Africa */
  {
    id: '2.1',
    parent: '1.1',
    name: 'Eastern Africa'
  },

  {
    id: '3.1',
    parent: '2.1',
    name: 'Ethiopia',
    value: 104957438
  }, {
    id: '3.2',
    parent: '2.1',
    name: 'Tanzania',
    value: 57310019
  }, {
    id: '3.3',
    parent: '2.1',
    name: 'Kenya',
    value: 49699862
  }, {
    id: '3.4',
    parent: '2.1',
    name: 'Uganda',
    value: 42862958
  }, {
    id: '3.5',
    parent: '2.1',
    name: 'Mozambique',
    value: 29668834
  }, {
    id: '3.6',
    parent: '2.1',
    name: 'Madagascar',
    value: 25570895
  }, {
    id: '3.7',
    parent: '2.1',
    name: 'Malawi',
    value: 18622104
  }, {
    id: '3.8',
    parent: '2.1',
    name: 'Zambia',
    value: 17094130
  }, {
    id: '3.9',
    parent: '2.1',
    name: 'Zimbabwe',
    value: 16529904
  }, {
    id: '3.10',
    parent: '2.1',
    name: 'Somalia',
    value: 14742523
  }, {
    id: '3.11',
    parent: '2.1',
    name: 'South Sudan',
    value: 12575714
  }, {
    id: '3.12',
    parent: '2.1',
    name: 'Rwanda',
    value: 12208407
  }, {
    id: '3.13',
    parent: '2.1',
    name: 'Burundi',
    value: 10864245
  }, {
    id: '3.14',
    parent: '2.1',
    name: 'Eritrea',
    value: 5068831
  }, {
    id: '3.15',
    parent: '2.1',
    name: 'Mauritius',
    value: 1265138
  }, {
    id: '3.16',
    parent: '2.1',
    name: 'Djibouti',
    value: 956985
  }, {
    id: '3.17',
    parent: '2.1',
    name: 'Réunion',
    value: 876562
  }, {
    id: '3.18',
    parent: '2.1',
    name: 'Comoros',
    value: 813912
  }, {
    id: '3.19',
    parent: '2.1',
    name: 'Mayotte',
    value: 253045
  }, {
    id: '3.20',
    parent: '2.1',
    name: 'Seychelles',
    value: 94737
  },

  {
    id: '2.5',
    parent: '1.1',
    name: 'Western Africa'
  },

  {
    id: '3.42',
    parent: '2.5',
    name: 'Nigeria',
    value: 190886311
  }, {
    id: '3.43',
    parent: '2.5',
    name: 'Ghana',
    value: 28833629
  }, {
    id: '3.44',
    parent: '2.5',
    name: 'Côte Ivoire',
    value: 24294750
  }, {
    id: '3.45',
    parent: '2.5',
    name: 'Niger',
    value: 21477348
  }, {
    id: '3.46',
    parent: '2.5',
    name: 'Burkina Faso',
    value: 19193382
  }, {
    id: '3.47',
    parent: '2.5',
    name: 'Mali',
    value: 18541980
  }, {
    id: '3.48',
    parent: '2.5',
    name: 'Senegal',
    value: 15850567
  }, {
    id: '3.49',
    parent: '2.5',
    name: 'Guinea',
    value: 12717176
  }, {
    id: '3.50',
    parent: '2.5',
    name: 'Benin',
    value: 11175692
  }, {
    id: '3.51',
    parent: '2.5',
    name: 'Togo',
    value: 7797694
  }, {
    id: '3.52',
    parent: '2.5',
    name: 'Sierra Leone',
    value: 7557212
  }, {
    id: '3.53',
    parent: '2.5',
    name: 'Liberia',
    value: 4731906
  }, {
    id: '3.54',
    parent: '2.5',
    name: 'Mauritania',
    value: 4420184
  }, {
    id: '3.55',
    parent: '2.5',
    name: 'The Gambia',
    value: 2100568
  }, {
    id: '3.56',
    parent: '2.5',
    name: 'Guinea-Bissau',
    value: 1861283
  }, {
    id: '3.57',
    parent: '2.5',
    name: 'Cabo Verde',
    value: 546388
  }, {
    id: '3.58',
    parent: '2.5',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 4049
  },

  {
    id: '2.3',
    parent: '1.1',
    name: 'North Africa'
  },

  {
    id: '3.30',
    parent: '2.3',
    name: 'Egypt',
    value: 97553151
  }, {
    id: '3.31',
    parent: '2.3',
    name: 'Algeria',
    value: 41318142
  }, {
    id: '3.32',
    parent: '2.3',
    name: 'Sudan',
    value: 40533330
  }, {
    id: '3.33',
    parent: '2.3',
    name: 'Morocco',
    value: 35739580
  }, {
    id: '3.34',
    parent: '2.3',
    name: 'Tunisia',
    value: 11532127
  }, {
    id: '3.35',
    parent: '2.3',
    name: 'Libya',
    value: 6374616
  }, {
    id: '3.36',
    parent: '2.3',
    name: 'Western Sahara',
    value: 552628
  },

  {
    id: '2.2',
    parent: '1.1',
    name: 'Central Africa'
  },

  {
    id: '3.21',
    parent: '2.2',
    name: 'Democratic Republic of the Congo',
    value: 81339988
  }, {
    id: '3.22',
    parent: '2.2',
    name: 'Angola',
    value: 29784193
  }, {
    id: '3.23',
    parent: '2.2',
    name: 'Cameroon',
    value: 24053727
  }, {
    id: '3.24',
    parent: '2.2',
    name: 'Chad',
    value: 14899994
  }, {
    id: '3.25',
    parent: '2.2',
    name: 'Congo',
    value: 5260750
  }, {
    id: '3.26',
    parent: '2.2',
    name: 'Central African Republic',
    value: 4659080
  }, {
    id: '3.27',
    parent: '2.2',
    name: 'Gabon',
    value: 2025137
  }, {
    id: '3.28',
    parent: '2.2',
    name: 'Equatorial Guinea',
    value: 1267689
  }, {
    id: '3.29',
    parent: '2.2',
    name: 'Sao Tome and Principe',
    value: 204327
  },

  {
    id: '2.4',
    parent: '1.1',
    name: 'South America'
  },

  {
    id: '3.37',
    parent: '2.4',
    name: 'South Africa',
    value: 56717156
  }, {
    id: '3.38',
    parent: '2.4',
    name: 'Namibia',
    value: 2533794
  }, {
    id: '3.39',
    parent: '2.4',
    name: 'Botswana',
    value: 2291661
  }, {
    id: '3.40',
    parent: '2.4',
    name: 'Lesotho',
    value: 2233339
  }, {
    id: '3.41',
    parent: '2.4',
    name: 'Swaziland',
    value: 1367254
  },

  /***********/

  /* America */
  {
    id: '2.9',
    parent: '1.2',
    name: 'South America'
  },

  {
    id: '3.98',
    parent: '2.9',
    name: 'Brazil',
    value: 209288278
  }, {
    id: '3.99',
    parent: '2.9',
    name: 'Colombia',
    value: 49065615
  }, {
    id: '3.100',
    parent: '2.9',
    name: 'Argentina',
    value: 44271041
  }, {
    id: '3.101',
    parent: '2.9',
    name: 'Peru',
    value: 32165485
  }, {
    id: '3.102',
    parent: '2.9',
    name: 'Venezuela',
    value: 31977065
  }, {
    id: '3.103',
    parent: '2.9',
    name: 'Chile',
    value: 18054726
  }, {
    id: '3.104',
    parent: '2.9',
    name: 'Ecuador',
    value: 16624858
  }, {
    id: '3.105',
    parent: '2.9',
    name: 'Bolivia',
    value: 11051600
  }, {
    id: '3.106',
    parent: '2.9',
    name: 'Paraguay',
    value: 6811297
  }, {
    id: '3.107',
    parent: '2.9',
    name: 'Uruguay',
    value: 3456750
  }, {
    id: '3.108',
    parent: '2.9',
    name: 'Guyana',
    value: 777859
  }, {
    id: '3.109',
    parent: '2.9',
    name: 'Suriname',
    value: 563402
  }, {
    id: '3.110',
    parent: '2.9',
    name: 'French Guiana',
    value: 282731
  }, {
    id: '3.111',
    parent: '2.9',
    name: 'Falkland Islands',
    value: 2910
  },

  {
    id: '2.8',
    parent: '1.2',
    name: 'Northern America'
  },

  {
    id: '3.93',
    parent: '2.8',
    name: 'United States',
    value: 324459463
  }, {
    id: '3.94',
    parent: '2.8',
    name: 'Canada',
    value: 36624199
  }, {
    id: '3.95',
    parent: '2.8',
    name: 'Bermuda',
    value: 61349
  }, {
    id: '3.96',
    parent: '2.8',
    name: 'Greenland',
    value: 56480
  }, {
    id: '3.97',
    parent: '2.8',
    name: 'Saint Pierre and Miquelon',
    value: 6320
  },

  {
    id: '2.7',
    parent: '1.2',
    name: 'Central America'
  },

  {
    id: '3.85',
    parent: '2.7',
    name: 'Mexico',
    value: 129163276
  }, {
    id: '3.86',
    parent: '2.7',
    name: 'Guatemala',
    value: 16913503
  }, {
    id: '3.87',
    parent: '2.7',
    name: 'Honduras',
    value: 9265067
  }, {
    id: '3.88',
    parent: '2.7',
    name: 'El Salvador',
    value: 6377853
  }, {
    id: '3.89',
    parent: '2.7',
    name: 'Nicaragua',
    value: 6217581
  }, {
    id: '3.90',
    parent: '2.7',
    name: 'Costa Rica',
    value: 4905769
  }, {
    id: '3.91',
    parent: '2.7',
    name: 'Panama',
    value: 4098587
  }, {
    id: '3.92',
    parent: '2.7',
    name: 'Belize',
    value: 374681
  },

  {
    id: '2.6',
    parent: '1.2',
    name: 'Caribbean'
  },

  {
    id: '3.59',
    parent: '2.6',
    name: 'Cuba',
    value: 11484636
  }, {
    id: '3.60',
    parent: '2.6',
    name: 'Haiti',
    value: 10981229
  }, {
    id: '3.61',
    parent: '2.6',
    name: 'Dominican Republic',
    value: 10766998
  }, {
    id: '3.62',
    parent: '2.6',
    name: 'Puerto Rico',
    value: 3663131
  }, {
    id: '3.63',
    parent: '2.6',
    name: 'Jamaica',
    value: 2890299
  }, {
    id: '3.64',
    parent: '2.6',
    name: 'Trinidad and Tobago',
    value: 1369125
  }, {
    id: '3.65',
    parent: '2.6',
    name: 'Guadeloupe',
    value: 449568
  }, {
    id: '3.66',
    parent: '2.6',
    name: 'Bahamas',
    value: 395361
  }, {
    id: '3.67',
    parent: '2.6',
    name: 'Martinique',
    value: 384896
  }, {
    id: '3.68',
    parent: '2.6',
    name: 'Barbados',
    value: 285719
  }, {
    id: '3.69',
    parent: '2.6',
    name: 'Saint Lucia',
    value: 178844
  }, {
    id: '3.70',
    parent: '2.6',
    name: 'Curaçao',
    value: 160539
  }, {
    id: '3.71',
    parent: '2.6',
    name: 'Saint Vincent and the Grenadines',
    value: 109897
  }, {
    id: '3.72',
    parent: '2.6',
    name: 'Grenada',
    value: 107825
  }, {
    id: '3.73',
    parent: '2.6',
    name: 'Aruba',
    value: 105264
  }, {
    id: '3.74',
    parent: '2.6',
    name: 'United States Virgin Islands',
    value: 104901
  }, {
    id: '3.75',
    parent: '2.6',
    name: 'Antigua and Barbuda',
    value: 102012
  }, {
    id: '3.76',
    parent: '2.6',
    name: 'Dominica',
    value: 73925
  }, {
    id: '3.77',
    parent: '2.6',
    name: 'Cayman Islands',
    value: 61559
  }, {
    id: '3.78',
    parent: '2.6',
    name: 'Saint Kitts and Nevis',
    value: 55345
  }, {
    id: '3.79',
    parent: '2.6',
    name: 'Sint Maarten',
    value: 40120
  }, {
    id: '3.80',
    parent: '2.6',
    name: 'Turks and Caicos Islands',
    value: 35446
  }, {
    id: '3.81',
    parent: '2.6',
    name: 'British Virgin Islands',
    value: 31196
  }, {
    id: '3.82',
    parent: '2.6',
    name: 'Caribbean Netherlands',
    value: 25398
  }, {
    id: '3.83',
    parent: '2.6',
    name: 'Anguilla',
    value: 14909
  }, {
    id: '3.84',
    parent: '2.6',
    name: 'Montserrat',
    value: 5177
  },
  /***********/

  /* Asia */
  {
    id: '2.13',
    parent: '1.3',
    name: 'Southern Asia'
  },

  {
    id: '3.136',
    parent: '2.13',
    name: 'India',
    value: 1339180127
  }, {
    id: '3.137',
    parent: '2.13',
    name: 'Pakistan',
    value: 197015955
  }, {
    id: '3.138',
    parent: '2.13',
    name: 'Bangladesh',
    value: 164669751
  }, {
    id: '3.139',
    parent: '2.13',
    name: 'Iran',
    value: 81162788
  }, {
    id: '3.140',
    parent: '2.13',
    name: 'Afghanistan',
    value: 35530081
  }, {
    id: '3.141',
    parent: '2.13',
    name: 'Nepal',
    value: 29304998
  }, {
    id: '3.142',
    parent: '2.13',
    name: 'Sri Lanka',
    value: 20876917
  }, {
    id: '3.143',
    parent: '2.13',
    name: 'Bhutan',
    value: 807610
  }, {
    id: '3.144',
    parent: '2.13',
    name: 'Maldives',
    value: 436330
  },

  {
    id: '2.11',
    parent: '1.3',
    name: 'Eastern Asia'
  },

  {
    id: '3.117',
    parent: '2.11',
    name: 'China',
    value: 1409517397
  }, {
    id: '3.118',
    parent: '2.11',
    name: 'Japan',
    value: 127484450
  }, {
    id: '3.119',
    parent: '2.11',
    name: 'South Korea',
    value: 50982212
  }, {
    id: '3.120',
    parent: '2.11',
    name: 'North Korea',
    value: 25490965
  }, {
    id: '3.121',
    parent: '2.11',
    name: 'Taiwan',
    value: 23626456
  }, {
    id: '3.122',
    parent: '2.11',
    name: 'Hong Kong',
    value: 7364883
  }, {
    id: '3.123',
    parent: '2.11',
    name: 'Mongolia',
    value: 3075647
  }, {
    id: '3.124',
    parent: '2.11',
    name: 'Macau',
    value: 622567
  },

  {
    id: '2.12',
    parent: '1.3',
    name: 'South-Eastern Asia'
  },

  {
    id: '3.125',
    parent: '2.12',
    name: 'Indonesia',
    value: 263991379
  }, {
    id: '3.126',
    parent: '2.12',
    name: 'Philippines',
    value: 104918090
  }, {
    id: '3.127',
    parent: '2.12',
    name: 'Vietnam',
    value: 95540800
  }, {
    id: '3.128',
    parent: '2.12',
    name: 'Thailand',
    value: 69037513
  }, {
    id: '3.129',
    parent: '2.12',
    name: 'Myanmar',
    value: 53370609
  }, {
    id: '3.130',
    parent: '2.12',
    name: 'Malaysia',
    value: 31624264
  }, {
    id: '3.131',
    parent: '2.12',
    name: 'Cambodia',
    value: 16005373
  }, {
    id: '3.132',
    parent: '2.12',
    name: 'Laos',
    value: 6858160
  }, {
    id: '3.133',
    parent: '2.12',
    name: 'Singapore',
    value: 5708844
  }, {
    id: '3.134',
    parent: '2.12',
    name: 'Timor-Leste',
    value: 1296311
  }, {
    id: '3.135',
    parent: '2.12',
    name: 'Brunei',
    value: 428697
    // 'color': ''
  },

  {
    id: '2.14',
    parent: '1.3',
    name: 'Western Asia'
  },

  {
    id: '3.145',
    parent: '2.14',
    name: 'Turkey',
    value: 80745020
  }, {
    id: '3.146',
    parent: '2.14',
    name: 'Iraq',
    value: 38274618
  }, {
    id: '3.147',
    parent: '2.14',
    name: 'Saudi Arabia',
    value: 32938213
  }, {
    id: '3.148',
    parent: '2.14',
    name: 'Yemen',
    value: 28250420
  }, {
    id: '3.149',
    parent: '2.14',
    name: 'Syria',
    value: 18269868
  }, {
    id: '3.150',
    parent: '2.14',
    name: 'Azerbaijan',
    value: 9827589
  }, {
    id: '3.151',
    parent: '2.14',
    name: 'Jordan',
    value: 9702353
  }, {
    id: '3.152',
    parent: '2.14',
    name: 'United Arab Emirates',
    value: 9400145
  }, {
    id: '3.153',
    parent: '2.14',
    name: 'Israel',
    value: 8321570
  }, {
    id: '3.154',
    parent: '2.14',
    name: 'Lebanon',
    value: 6082357
  }, {
    id: '3.155',
    parent: '2.14',
    name: 'Palestine',
    value: 4920724
  }, {
    id: '3.156',
    parent: '2.14',
    name: 'Oman',
    value: 4636262
  }, {
    id: '3.157',
    parent: '2.14',
    name: 'Kuwait',
    value: 4136528
  }, {
    id: '3.158',
    parent: '2.14',
    name: 'Georgia',
    value: 3912061
  }, {
    id: '3.159',
    parent: '2.14',
    name: 'Armenia',
    value: 2930450
  }, {
    id: '3.160',
    parent: '2.14',
    name: 'Qatar',
    value: 2639211
  }, {
    id: '3.161',
    parent: '2.14',
    name: 'Bahrain',
    value: 1492584
  },

  {
    id: '2.10',
    parent: '1.3',
    name: 'Central Asia'
  },

  {
    id: '3.112',
    parent: '2.10',
    name: 'Uzbekistan',
    value: 31910641
  }, {
    id: '3.113',
    parent: '2.10',
    name: 'Kazakhstan',
    value: 18204499
  }, {
    id: '3.114',
    parent: '2.10',
    name: 'Tajikistan',
    value: 8921343
  }, {
    id: '3.115',
    parent: '2.10',
    name: 'Kyrgyzstan',
    value: 6045117
  }, {
    id: '3.116',
    parent: '2.10',
    name: 'Turkmenistan',
    value: 5758075
  },
  /***********/

  /* Europe */
  {
    id: '2.15',
    parent: '1.4',
    name: 'Eastern Europe'
  },

  {
    id: '3.162',
    parent: '2.15',
    name: 'Russia',
    value: 143989754
  }, {
    id: '3.163',
    parent: '2.15',
    name: 'Ukraine',
    value: 44222947
  }, {
    id: '3.164',
    parent: '2.15',
    name: 'Poland',
    value: 38170712
  }, {
    id: '3.165',
    parent: '2.15',
    name: 'Romania',
    value: 19679306
  }, {
    id: '3.166',
    parent: '2.15',
    name: 'Czechia',
    value: 10618303
  }, {
    id: '3.167',
    parent: '2.15',
    name: 'Hungary',
    value: 9721559
  }, {
    id: '3.168',
    parent: '2.15',
    name: 'Belarus',
    value: 9468338
  }, {
    id: '3.169',
    parent: '2.15',
    name: 'Bulgaria',
    value: 7084571
  }, {
    id: '3.170',
    parent: '2.15',
    name: 'Slovakia',
    value: 5447662
  }, {
    id: '3.171',
    parent: '2.15',
    name: 'Moldova',
    value: 4051212
  }, {
    id: '3.172',
    parent: '2.15',
    name: 'Cyprus',
    value: 1179551
  },

  {
    id: '2.16',
    parent: '1.4',
    name: 'Northern Europe'
  },

  {
    id: '3.173',
    parent: '2.16',
    name: 'United Kingdom',
    value: 66181585
  }, {
    id: '3.174',
    parent: '2.16',
    name: 'Sweden',
    value: 9910701
  }, {
    id: '3.175',
    parent: '2.16',
    name: 'Denmark',
    value: 5733551
  }, {
    id: '3.176',
    parent: '2.16',
    name: 'Finland',
    value: 5523231
  }, {
    id: '3.177',
    parent: '2.16',
    name: 'Norway',
    value: 5305383
  }, {
    id: '3.178',
    parent: '2.16',
    name: 'Ireland',
    value: 4761657
  }, {
    id: '3.179',
    parent: '2.16',
    name: 'Lithuania',
    value: 2890297
  }, {
    id: '3.180',
    parent: '2.16',
    name: 'Latvia',
    value: 1949670
  }, {
    id: '3.181',
    parent: '2.16',
    name: 'Estonia',
    value: 1309632
  }, {
    id: '3.182',
    parent: '2.16',
    name: 'Iceland',
    value: 335025
  }, {
    id: '3.183',
    parent: '2.16',
    name: 'Guernsey and  Jersey',
    value: 165314
  }, {
    id: '3.184',
    parent: '2.16',
    name: 'Isle of Man',
    value: 84287
  }, {
    id: '3.185',
    parent: '2.16',
    name: 'Faroe Islands',
    value: 49290
  },

  {
    id: '2.17',
    parent: '1.4',
    name: 'Southern Europe'
  },

  {
    id: '3.186',
    parent: '2.17',
    name: 'Italy',
    value: 59359900
  }, {
    id: '3.187',
    parent: '2.17',
    name: 'Spain',
    value: 46354321
  }, {
    id: '3.188',
    parent: '2.17',
    name: 'Greece',
    value: 11159773
  }, {
    id: '3.189',
    parent: '2.17',
    name: 'Portugal',
    value: 10329506
  }, {
    id: '3.190',
    parent: '2.17',
    name: 'Serbia',
    value: 8790574
  }, {
    id: '3.191',
    parent: '2.17',
    name: 'Croatia',
    value: 4189353
  }, {
    id: '3.192',
    parent: '2.17',
    name: 'Bosnia and Herzegovina',
    value: 3507017
  }, {
    id: '3.193',
    parent: '2.17',
    name: 'Albania',
    value: 2930187
  }, {
    id: '3.194',
    parent: '2.17',
    name: 'Republic of Macedonia',
    value: 2083160
  }, {
    id: '3.195',
    parent: '2.17',
    name: 'Slovenia',
    value: 2079976
  }, {
    id: '3.196',
    parent: '2.17',
    name: 'Montenegro',
    value: 628960
  }, {
    id: '3.197',
    parent: '2.17',
    name: 'Malta',
    value: 430835
  }, {
    id: '3.198',
    parent: '2.17',
    name: 'Andorra',
    value: 76965
  }, {
    id: '3.199',
    parent: '2.17',
    name: 'Gibraltar',
    value: 34571
  }, {
    id: '3.200',
    parent: '2.17',
    name: 'San Marino',
    value: 33400
  }, {
    id: '3.201',
    parent: '2.17',
    name: 'Vatican City',
    value: 792
  },

  {
    id: '2.18',
    parent: '1.4',
    name: 'Western Europe'
  },

  {
    id: '3.202',
    parent: '2.18',
    name: 'Germany',
    value: 82114224
  }, {
    id: '3.203',
    parent: '2.18',
    name: 'France',
    value: 64979548
  }, {
    id: '3.204',
    parent: '2.18',
    name: 'Netherlands',
    value: 17035938
  }, {
    id: '3.205',
    parent: '2.18',
    name: 'Belgium',
    value: 11429336
  }, {
    id: '3.206',
    parent: '2.18',
    name: 'Austria',
    value: 8735453
  }, {
    id: '3.207',
    parent: '2.18',
    name: 'Switzerland',
    value: 8476005
  }, {
    id: '3.208',
    parent: '2.18',
    name: 'Luxembourg',
    value: 583455
  }, {
    id: '3.209',
    parent: '2.18',
    name: 'Monaco',
    value: 38695
  }, {
    id: '3.210',
    parent: '2.18',
    name: 'Liechtenstein',
    value: 37922
  },
  /***********/

  /* Oceania */
  {
    id: '2.19',
    parent: '1.5',
    name: 'Australia and New Zealand'
  },

  {
    id: '3.211',
    parent: '2.19',
    name: 'Australia',
    value: 24450561
  }, {
    id: '3.212',
    parent: '2.19',
    name: 'New Zealand',
    value: 4705818
  },

  {
    id: '2.20',
    parent: '1.5',
    name: 'Melanesia'
  },

  {
    id: '3.213',
    parent: '2.20',
    name: 'Papua New Guinea'
  }, {
    id: '3.214',
    parent: '2.20',
    name: 'Fiji',
    value: 905502
  }, {
    id: '3.215',
    parent: '2.20',
    name: 'Solomon Islands',
    value: 611343
  }, {
    id: '3.216',
    parent: '2.20',
    name: 'New Caledonia',
    value: 276255
  }, {
    id: '3.217',
    parent: '2.20',
    name: 'Vanuatu',
    value: 276244
  },

  {
    id: '2.21',
    parent: '1.5',
    name: 'Micronesia'
  },

  {
    id: '3.218',
    parent: '2.21',
    name: 'Guam',
    value: 164229
  }, {
    id: '3.219',
    parent: '2.21',
    name: 'Kiribati',
    value: 116398
  }, {
    id: '3.220',
    parent: '2.21',
    name: 'Federated States of Micronesia',
    value: 105544
  }, {
    id: '3.221',
    parent: '2.21',
    name: 'Northern Mariana Islands',
    value: 55144
  }, {
    id: '3.222',
    parent: '2.21',
    name: 'Marshall Islands',
    value: 53127
  }, {
    id: '3.223',
    parent: '2.21',
    name: 'Palau',
    value: 21729
  }, {
    id: '3.224',
    parent: '2.21',
    name: 'Nauru',
    value: 11359
  },

  {
    id: '2.22',
    parent: '1.5',
    name: 'Polynesia'
  },

  {
    id: '3.225',
    parent: '2.22',
    name: 'French Polynesia',
    value: 283007
  }, {
    id: '3.226',
    parent: '2.22',
    name: 'Samoa',
    value: 196440
  }, {
    id: '3.227',
    parent: '2.22',
    name: 'Tonga',
    value: 108020
  }, {
    id: '3.228',
    parent: '2.22',
    name: 'American Samoa',
    value: 55641
  }, {
    id: '3.229',
    parent: '2.22',
    name: 'Cook Islands',
    value: 17380
  }, {
    id: '3.230',
    parent: '2.22',
    name: 'Wallis and Futuna',
    value: 11773
  }, {
    id: '3.231',
    parent: '2.22',
    name: 'Tuvalu',
    value: 11192
  }, {
    id: '3.232',
    parent: '2.22',
    name: 'Niue',
    value: 1618
  }, {
    id: '3.233',
    parent: '2.22',
    name: 'Tokelau',
    value: 1300
  }];

  public options: any = {
    chart: {
      height: '100%'
    },

    title: {
      text: 'World population 2017'
    },
    subtitle: {
      text: 'USA'
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click: (event) => this.show(event)
        }
      }
    },
    series: [{
      type: "sunburst",
      data: this.data,
      allowDrillToNode: true,
      cursor: 'pointer',
      dataLabels: {
        format: '{point.name}',
        filter: {
          property: 'innerArcLength',
          operator: '>',
          value: 16
        },
        rotationMode: 'circular'
      },
      levels: [{
        level: 1,
        levelIsConstant: false,
        dataLabels: {
          filter: {
            property: 'outerArcLength',
            operator: '>',
            value: 64
          }
        }
      }, {
        level: 2,
        colorByPoint: true
      },
      {
        level: 3,
        colorVariation: {
          key: 'brightness',
          to: -0.5
        }
      }, {
        level: 4,
        colorVariation: {
          key: 'brightness',
          to: 0.5
        }
      }]

    }],
    tooltip: {
      headerFormat: "",
      pointFormat: 'The population of <b>{point.name}</b> is <b>{point.value}</b>'
    }


  }

  testDatacharts = [
    [0, 0, 10], [0, 1, 19], [0, 2, 8], [0, 3, 24], [0, 4, 67],
                     [1, 0, 92], [1, 1, 58], [1, 2, 78], [1, 3, 117], [1, 4, 48],
  [2, 0, 35], [2, 1, 15], [2, 2, 123], [2, 3, 64], [2, 4, 52],
  [3, 0, 72], [3, 1, 132], [3, 2, 114], [3, 3, 19], [3, 4, 16],
  [4, 0, 38], [4, 1, 5], [4, 2, 8], [4, 3, 117], [4, 4, 115],
  [5, 0, 88], [5, 1, 32], [5, 2, 12], [5, 3, 6], [5, 4, 120],
  [6, 0, 13], [6, 1, 44], [6, 2, 88], [6, 3, 98], [6, 4, 96],
  [7, 0, 31], [7, 1, 1], [7, 2, 82], [7, 3, 32], [7, 4, 30],
  [8, 0, 85], [8, 1, 97], [8, 2, 123], [8, 3, 64], [8, 4, 84],
  [9, 0, 47], [9, 1, 114], [9, 2, 31], [9, 3, 48], [9, 4, 91]];

  testDatachart =[]

  @ViewChild('heatmap') mapElement: ElementRef;
  map: any
  heatmap: any

 
  mapcentre = new google.maps.LatLng(1.2921, 36.8219);
  s: string;
  t: string;
  grouped = false;
  sunBurst = false;
  preselected = false
  hichart2: Chart;
  groupedBy: any;
  graphdata: any = {};
  minDate: Date;
  maxDate: Date;
  seachtext: string;
  charttype = "line";
  currentquery: string = "";
  seriesdate: any;
  graphseries: Array<any>;
  week: Date = new Date()
  month: Date = new Date()
  threeMonth: Date = new Date()
  dateSelect = new FormControl(new Date())
  dateSelected: any;



  startdate: FormControl = new FormControl(new Date(), Validators.required);
  enddate: FormControl = new FormControl(new Date(), Validators.required);
  selected = {
    filters: {
      category: "",
      location: "",
      subcategory: "",
      region: "",
      productlabel: "",
      unitname: "",
      manufacturer: "",
      year: "",
      month: "",
      week: "",
    },
    startdate: this.startdate.value,
    enddate: this.enddate.value,
    groupedby: "",
    viewtype: "",
    measurent: "",
    axiscolumn: "",
  };
  filterform: FormGroup;
  filteredlocations: Array<any> = new Array();
  filteredregions: Array<any> = new Array();
  filteredmanufactures: Array<any> = new Array();
  filteredbrand: Array<any> = new Array();

  filteredunit: Array<any> = new Array();
  filteredcategory: Array<any> = new Array();
  filteredsubcategory: Array<any> = new Array();
  requestingreport: boolean = false;
  public groupedpie: boolean = false;
  allSelected = false;

  @ViewChild('mySelLocation') selectAllLocation: MatSelect;
  @ViewChild('mySelRegion') selectAllRegion: MatSelect;
  @ViewChild('mySelCategory') selectAllCategory: MatSelect;
  @ViewChild('mySelSubcategory') selectAllSubcategory: MatSelect;
  @ViewChild('mySelManufacturer') selectAllManufacturer: MatSelect;
  @ViewChild('mySelProduct') selectAllProduct: MatSelect;
  @ViewChild('mySelSku') selectAllSku: MatSelect;
  seriesss: any;
  axisData: any;


  constructor(
    private elementRef: ElementRef,
    public postauth: PostauthService,
    public notiservice: NotifierService,
    public route: Router
  ) {
    this.week.setDate(this.week.getDate() - 7);
    this.month.setDate(this.month.getDate() - 30);
    this.threeMonth.setDate(this.threeMonth.getDate() - 90);
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();
    this.groupedBy = "";

    this.postauth.viewtitle = "Heat Map Chart";
    if (this.postauth.assigned_data["locations"] == undefined) {
      // this.filteredlocations = Array.from(this.postauth.assigned_data['locations']);
    }

    this.simple_chart_config = {
      chart: {
        container: "#tree-simple",
      },

      nodeStructure: {
        text: { name: "Parent node" },
        children: [
          {
            text: { name: "First child" },
          },
          {
            text: { name: "Second child" },
          },
        ],
      },
    };

    this.filterform = new FormGroup({
      location: new FormControl(""),
      region: new FormControl(""),
      brand: new FormControl(""),
      unit: new FormControl(""),
      manufacturer: new FormControl(""),
      category: new FormControl(""),
      subcategory: new FormControl(""),
      year: new FormControl(""),
      month: new FormControl(""),
      week: new FormControl(""),
      locationfilter: new FormControl(""),
    });
  }
  orgData: OrgData = {
    name: "Kapa Oil Rfinary",
    type: "Company",

    children: [
      {
        name: "Detergents",
        type: "VP",
        children: [
          {
            name: "Toss",
            type: "manager",
            children: [
              {
                name: "Toss Whil",
                type: "Toasw",
                children: [
                  { name: "White", type: "15 grams", children: [] },
                  { name: "White", type: "15 grams", children: [] },
                  { name: "White", type: "15 grams", children: [] },
                ],
              },
            ],
          },
          {
            name: "Jamma",
            type: "Manager",
            children: [],
          },
        ],
      },
      {
        name: "Black Widow",
        type: "VP",
        children: [
          {
            name: "Hulk",
            type: "manager",
            children: [
              {
                name: "Spiderman",
                type: "Intern",
                children: [],
              },
            ],
          },
          {
            name: "Thor",
            type: "Manager",
            children: [
              {
                name: "Loki",
                type: "Team Lead",
                children: [],
              },
            ],
          },
        ],
      },
    ],
  };
  private Treant: any;
  simple_chart_config: any;

  chart = new Chart({
    chart: {
      type: "line",
    },
    yAxis: {
      title: {
        text: "Number of Employees",
      },
    },

    xAxis: {
      accessibility: {
        rangeDescription: "Range: 2010 to 2017",
      },
      categories: ["Oils", "Fats", "Vegitables"],
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        shadow: false,
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: true,
    },

    series: [
      {
        type: "spline",
        name: "Average",
        data: [3, 2.67, 3, 6.33, 3.33],
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: "white",
        },
      },
      {
        type: "column",
        name: "Line 1",
        data: [
          [5, 2],
          [6, 3],
          [8, 2],
        ],
      },
      {
        type: "line",
        name: "Line 2",
        data: [11, 22, 33],
      },
    ],
    legend: {
      align: "left",
      verticalAlign: "top",
      borderWidth: 0,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  });

  ngAfterViewInit() {
    ;
    this.elementRef.nativeElement.addEventListener(
      "click",
      this.onClick.bind(this)
    );
  }

  onClick(event) {
    console.log(event);
  }

  // add point to chart serie
  add() {
    // john:Option ={}
    // new Chart({chart:{},})
    this.chart.addSeries(
      {
        type: "pie",
        name: "added series",
        data: [
          Math.floor(Math.random() * 10),
          Math.floor(Math.random() * 10),
          Math.floor(Math.random() * 10),
          Math.floor(Math.random() * 10),
          Math.floor(Math.random() * 10),
          Math.floor(Math.random() * 10),
          Math.floor(Math.random() * 10),
        ],
      },
      true,
      true
    );
    this.chart.addPoint(Math.floor(Math.random() * 10));
  }

  ngOnInit() {
  
    // alert( this.postauth.assigned_data['location'] )
  }
  refreshData() {
    this.groupedpie = false;
    if (
      this.selected.measurent === undefined ||
      this.selected.measurent === ""
    ) {
      this.notiservice.notify("warning", "Measurent required");
    }
    if (
      this.selected.axiscolumn === undefined ||
      this.selected.axiscolumn === ""
    ) {
      this.notiservice.notify("warning", "Primary Display column required");
    } else {
      this.requestingreport = true;

      this.selected["grouped"] = this.grouped;
      this.postauth.fetchData(this.selected, "price_reports").subscribe(
        (success) => {
         
          this.requestingreport = false;
          this.currentquery = success["query"];
          this.graphdata = success;
          this.loadHeatMap(0);
        },
        (error) => {
          this.requestingreport = false;
          console.log(error);
        }
      );
    }
  }
  heatmapchange(e){
    this.selectedarrayposition=e;
this.loadHeatMap(this.selectedarrayposition);
  }
 async loadHeatMap(datapostion:number){
let geocoder = new google.maps.Geocoder;
let heatMapData:Array<any>=[];

let totalweight=Array.from(this.graphdata.series[datapostion].data).reduce((sum:number,d:number)=>d,0);
let categoryarraypos=0;

 await Array.from(this.graphdata.categories).forEach(async element=>{
console.log(element+'<<about  to  check  location..');
let graphdata=this.graphdata;
  await geocoder.geocode({ 'address': element }, function (results, status) {
    
    
    if(results!==undefined&&results!=null&&results!=='null'){
   
    let pos = { "lat": results[0].geometry.location.lat(), "lng": results[0].geometry.location.lng() };
    // alert(pos);  

  heatMapData.push({location: new google.maps.LatLng(results[0].geometry.location.lat(),results[0].geometry.location.lng()), weight: (graphdata.series[datapostion].data[categoryarraypos]/Number(totalweight))})
  categoryarraypos++; 
}else{
    categoryarraypos++;
    console.log('skipping  location>>'+element+'>> error  geocoding..');
  }  

  if(categoryarraypos===graphdata.categories.length){
    // alert('am done..i have lopped>>'+categoryarraypos);
    let mapcentre = new google.maps.LatLng(-1.2921, 36.8219);
    console.log(heatMapData)
    this.map = new google.maps.Map(document.getElementById('heatmap'), {
      center:mapcentre,
      zoom: 10,
      mapTypeId: 'hybrid'
    });
    
   this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: heatMapData
    });
    this.heatmap.setMap(this.map);
  
  }



})





});

 
    

}

  startDateChage(event) {

    this.startdate.setValue(event);
    // console.log( formatDate(event,'y-MM-dd','en-US'))
    // this.selected.startdate = event;
    this.selected.startdate = formatDate(event, "y-MM-dd", "en-US");
  }
  endDateChange(event) {
    // this.selected.enddate = event;

    event.setDate(event.getDate() - 7);
    console.log(event);
    this.selected.enddate = formatDate(event, "y-MM-dd", "en-US");
    this.enddate.setValue(event);
  }


  enddateFilter = (d: Date | null): boolean => {
    const timediff =
      new Date(this.selected.enddate).getTime() -
      new Date(this.selected.startdate).getTime();
    console.log(timediff);
    if (timediff > 1000 * 60 * 60 * 24 * 90) {
      this.notiservice.notify(
        "error",
        "Time range of over of over 6 months not allowed"
      );
      this.selected.enddate = this.selected.startdate;
      return false;
    } else {
      return true;
    }
  };
  startdateFilter = (d: Date | null): boolean => true;

  filterValues(element: string, index, array) {
    return element["name"].toString().toLowerCase().includes(this.seachtext);
  }

  filterFilter(event, fileld: string) {
    if (event !== "") {
      switch (fileld) {
        case "location": {
          this.filteredlocations = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data["location"]).forEach(
            (element) => {
              if (
                element["name"]
                  .toString()
                  .toLowerCase()
                  .includes(this.seachtext)
              ) {
                this.filteredlocations.push(element);
              }
            }
          );
          break;
        }
        case "manufacture": {
          this.filteredmanufactures = [];
          this.seachtext = event;

          // tslint:disable-next-line: no-string-literal
          Array.from(this.postauth.assigned_data["manufacturer"]).forEach(
            (element) => {
              if (
                element["name"]
                  .toString()
                  .toLowerCase()
                  .includes(this.seachtext)
              ) {
                this.filteredmanufactures.push(element);
              }
            }
          );
          break;
        }
        case "categories": {
          this.filteredcategory = [];
          this.seachtext = event;

          // tslint:disable-next-line: no-string-literal
          Array.from(this.postauth.assigned_data["category"]).forEach(
            (element) => {
              if (
                element["name"]
                  .toString()
                  .toLowerCase()
                  .includes(this.seachtext)
              ) {
                this.filteredcategory.push(element);
              }
            }
          );
          break;
        }
        case "subCategories": {
          this.filteredsubcategory = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data["subCategory"]).forEach(
            (element) => {
              if (
                element["name"]
                  .toString()
                  .toLowerCase()
                  .includes(this.seachtext)
              ) {
                this.filteredsubcategory.push(element);
              }
            }
          );
          break;
        }
        case "prodcutLabels": {
          this.filteredbrand = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data["products"]).forEach(
            (element) => {
              if (
                element["name"]
                  .toString()
                  .toLowerCase()
                  .includes(this.seachtext)
              ) {
                this.filteredbrand.push(element);
              }
            }
          );
          break;
        }
        case "unitNames": {
          this.filteredunit = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data["sku"]).forEach((element) => {
            if (
              element["name"].toString().toLowerCase().includes(this.seachtext)
            ) {
              this.filteredunit.push(element);
            }
          });
          break;
        }
        case "regions": {
          this.filteredregions = [];
          this.seachtext = event;

          Array.from(this.postauth.assigned_data["regions"]).forEach(
            (element) => {
              if (
                element["name"]
                  .toString()
                  .toLowerCase()
                  .includes(this.seachtext)
              ) {
                this.filteredregions.push(element);
              }
            }
          );
          break;
        }
      }
    } else {
      switch (fileld) {
        case "location": {
          this.filteredlocations = this.postauth.assigned_data["location"];
          break;
        }
        case "manufacture": {
          this.filteredmanufactures = this.postauth.assigned_data[
            "manufacturer"
          ];
          break;
        }
        case "categories": {
          this.filteredcategory = this.postauth.assigned_data["category"];
          break;
        }
        case "subCategories": {
          this.filteredsubcategory = this.postauth.assigned_data["subCategory"];
          break;
        }
        case "prodcutLabels": {
          this.filteredbrand = this.postauth.assigned_data["products"];

          break;
        }
        case "unitNames": {
          this.filteredunit = this.postauth.assigned_data["sku"];
          break;
        }

        case "regions": {
          this.filteredregions = this.postauth.assigned_data["regions"];
          break;
        }
      }
    }
  }
  chartTypeChage(charttype) {
   
    this.charttype = charttype;
    if (this.graphdata.series !== undefined) {
      if (
        Array.from(this.graphdata.series).length > 1 &&
        charttype === "pie" &&
        !this.grouped
      ) {
        this.notiservice.notify(
          "warning",
          "The Dataset wont generate a valid pie chart"
        );
      } else {

        const piechartdata: Array<{}> = new Array();
        // let graphseries: Array<any>;

        if (this.grouped) {
          this.graphdata.series = this.graphdata.series;
          this.groupedpie = false;
         
        }
        else {
          this.groupedpie = false;
          
          this.graphseries = Array.from(this.graphdata.series).map((seri) => {
            if (charttype === "pie") {
              let recordnumber = 0;
              this.charttype = "pie";
              Array.from(seri["data"]).forEach((dtpoint) => {
                piechartdata.push({
                  name: this.graphdata.categories[recordnumber],
                  y: dtpoint,
                });
                recordnumber++;
              });
              this.seriesdate = {
                name: "Brands",
                colorByPoint: true,
                data: piechartdata,
              };
            } else {
              this.charttype = charttype;
              //udefenied in grouped data
              if (seri["type"] !== undefined) {
                seri["type"] = charttype;
              }
            }

            return seri;
          });
        }
        ;

        if (charttype !== "pie") {
          //edited not pie confirm grouped
         
          if (this.graphseries === undefined){
          this.selected["grouped"] = !this.grouped;
              this.postauth.fetchData(this.selected, "price_reports").subscribe(
                (success) => {
                  let grapdata: any = {};
               grapdata= success
              
            this.hichart2 = new Chart({
              chart: {
                type: this.charttype,
                height: 800,
              },
              yAxis: {
                min: 0,
                title: {
                  text: this.graphdata.yAxisTitle,
                },
              },
              xAxis: { categories: this.graphdata.categories },
              title: {
                text: "",
              },
              credits: {
                enabled: false,
              },
              // series: [
              //   {
              //     type: undefined,
              //     data: this.graphdata.series,
              //   },
              // ],
              series:grapdata.series
            });
          })

          }else{
          
            this.hichart2 = new Chart({
              chart: {
                type: this.charttype,
                height: 800,
              },
              yAxis: {
                min: 0,
                title: {
                  text: this.graphdata.yAxisTitle,
                },
              },
              xAxis: { categories: this.graphdata.categories },
              title: {
                text: "",
              },
              credits: {
                enabled: false,
              },
              // series: [
              //   {
              //     type: undefined,
              //     data: this.graphdata.series,
              //   },
              // ],
              series: this.graphseries
            });
           
          }
         
        } else {
          if (this.grouped) {

            this.groupedpie = true;
            this.data = [];
            var uniqsel = this.selected;
            uniqsel['grouped'] = false;

            this.postauth.fetchData(uniqsel, 'price_reports').subscribe(success => {
              this.currentquery = success['query'];
             let piedata: any = {};
              piedata= success;
              for (var arraypos = 0; arraypos < Array.from(piedata.categories).length; arraypos++) {
                piedata.categories[arraypos];
                var parentid = (arraypos + 1);
                this.data.push(
                  {
                    id: parentid + '',
                    parent: '0.0',
                    name: piedata.categories[arraypos]
                  }
                )
            
                var chileid: number = parentid + 0.1;
                Array.from(piedata.series).forEach(serie => {
                  chileid = chileid + 0.1;
                  this.data.push(
                    {
                      id: Number(chileid).toFixed(1) + '',
                      parent: parentid + '',
                      name: serie['name'],
                      value: serie['data'][arraypos]
                    }
                  )
                  // this.s =serie['name'];
                  // this.t = serie['data'][arraypos];
              
                })

              }
              if (this.data.length > 1000) {
                this.notiservice.notify('warning', 'Large Dataset ' + this.data.length + ' to generate grouped pie chart');
              } else {
                let newoptions: any = {
                  chart: {
                    height: '100%'
                  },

                  title: {
                    text: ''
                  },
                  subtitle: {
                    text: ''
                  },
                  plotOptions: {
                    series: {
                      cursor: 'pointer',
                      events: {
                        click: (event) => this.show(event)
                      }
                    }
                  },
                  series: [{
                    type: "sunburst",
                    data: this.data,
                    allowDrillToNode: true,
                    cursor: 'pointer',
                    dataLabels: {
                      format: '{point.name}',
                      filter: {
                        property: 'innerArcLength',
                        operator: '>',
                        value: 16
                      },
                      rotationMode: 'circular'
                    },
                    levels: [{
                      level: 1,
                      levelIsConstant: false,
                      dataLabels: {
                        filter: {
                          property: 'outerArcLength',
                          operator: '>',
                          value: 64
                        }
                      }
                    }, {
                      level: 2,
                      colorByPoint: true
                    },
                    {
                      level: 3,
                      colorVariation: {
                        key: 'brightness',
                        to: -0.5
                      }
                    }, {
                      level: 4,
                      colorVariation: {
                        key: 'brightness',
                        to: 0.5
                      }
                    }]

                  }],
                  tooltip: {
                    headerFormat: "",
                    pointFormat: '<b>{point.name}</b> is <b>{point.value}</b>'
                  }


                }
                Highcharts.chart('groupedpiechart', newoptions);
              }
            })

          } else {
            this.groupedpie = false;
            this.hichart2 = new Chart({
              chart: {
                type: "pie",
                height: 800,
              },

              xAxis: { categories: this.graphdata.categories },
              title: {
                text: "",
              },
              credits: {
                enabled: false,
              },
              series: [{ type: "pie", data: piechartdata }],
            });
          }
          






        }
      }
    } else {
      this.notiservice.notify(
        "warning",
        "No Data to generate chart"
      );
    }
  }
  toggle(e) {
    this.grouped = e.target.checked;

    this.refreshData();
  }

  setDatepicker(event) {
  
    console.log(event)
    if (this.week == event) {
      this.dateSelect = new FormControl(this.week)
      this.selected.startdate = this.dateSelect.value;

    } else if (this.month == event) {
      this.dateSelect = new FormControl(this.month)
      this.selected.startdate = this.dateSelect.value;

    } else if (this.threeMonth == event) {
      this.dateSelect = new FormControl(this.threeMonth)
      this.selected.startdate = this.dateSelect.value;

    } else {
      this.dateSelect = null
    }

  }

  show(event) {
    var popup = confirm("Proceed to Dashboard?")
    if (popup == true) {
      this.route.navigateByUrl('/home/salesrep')
    } else {
      this.notiservice.notify("Success", "A view selected Node Pie chart data ");
    }
  }

  toggleSelectAll(selectAllValue: boolean) {

  }

  selectAll(field: string) {
    this.allSelected = !this.allSelected;  // to control select-unselect

    if (this.allSelected) {

      switch (field) {
        case "location": {
          this.selectAllLocation.options.forEach((item: MatOption) => item.select());
          // this.selectAllLocation.close();
          break;
        }
        case "manufacture": {
          this.selectAllManufacturer.options.forEach((item: MatOption) => item.select());
          // this.selectAllManufacturer.close();
          break;

        }
        case "categories": {
          this.selectAllCategory.options.forEach((item: MatOption) => item.select());
          // this.selectAllCategory.close();
          break;

        }
        case "subCategories": {
          this.selectAllSubcategory.options.forEach((item: MatOption) => item.select());
          // this.selectAllSubcategory.close();
          break;

        }
        case "prodcutLabels": {
          this.selectAllProduct.options.forEach((item: MatOption) => item.select());
          // this.selectAllProduct.close();
          break;

        }
        case "unitNames": {
          this.selectAllSku.options.forEach((item: MatOption) => item.select());
          // this.selectAllSku.close();
          break;

        }
        case "regions": {
          this.selectAllRegion.options.forEach((item: MatOption) => item.select());
          // this.selectAllRegion.close();
          break;

        }

      }
    }

    else {

      switch (field) {
        case "location": {
          this.selectAllLocation.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllLocation.close();
          break;
        }
        case "manufacture": {
          this.selectAllManufacturer.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllManufacturer.close();
          break;

        }
        case "categories": {
          this.selectAllCategory.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllCategory.close();
          break;

        }
        case "subCategories": {
          this.selectAllSubcategory.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllSubcategory.close();
          break;

        }
        case "prodcutLabels": {
          this.selectAllProduct.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllProduct.close();
          break;

        }
        case "unitNames": {
          this.selectAllSku.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllSku.close();
          break;

        }
        case "regions": {
          this.selectAllRegion.options.forEach((item: MatOption) => { item.deselect() });
          this.selectAllRegion.close();
          break;

        }

      }

    }
  }
}
