import { PreAuthService } from 'src/app/services/pre-auth.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { Event, Router, NavigationEnd,NavigationCancel,NavigationStart,NavigationError, RouterEvent} from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { environment } from 'src/environments/environment';
import { QuantityDiscount } from './models/manportal';
import { PostauthService } from './services/postauth.service';
import { NotificationService } from './services/notification.service';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent  implements OnInit{
  progress_spinner= true;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  @ViewChild('sessionmodal', { static: false }) sessionModal: ModalDirective;

  

  constructor(private postauth:PostauthService,public router: Router, private idle: Idle, private keepalive: Keepalive, private preauth: PreAuthService, 
   
   
    private modalService: BsModalService,public notiservice: NotificationService){

    

    this.router.events.subscribe((onRoute: Event)=>{

      if (onRoute instanceof NavigationStart){
        this.progress_spinner=true;
      }
      if(onRoute instanceof NavigationEnd||onRoute instanceof NavigationError||onRoute instanceof NavigationCancel){
        this.progress_spinner=false;
      }
    })
// sets an idle timeout of in seconds, for testing purposes.
idle.setIdle(environment.screen_idle_time);
// sets a timeout period of in  seconds. after 305 seconds of inactivity, the user will be considered timed out.
idle.setTimeout(environment.user_input_countdown);
// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

idle.onIdleEnd.subscribe(() => { 
  this.idleState = 'No longer idle.'
  console.log(this.idleState);
  this.reset();
});

idle.onTimeout.subscribe(() => {
  this.childModal.hide();
  this.idleState = 'Timed out!';
  this.timedOut = true;
  console.log(this.idleState);
  this.router.navigate(['/guest/lock']);
});

idle.onIdleStart.subscribe(() => {
    this.idleState = 'You\'ve gone idle!'
    console.log(this.idleState);
    this.childModal.show();
});

idle.onTimeoutWarning.subscribe((countdown) => {
  this.idleState = 'You will time out in ' + countdown + ' seconds!'
  console.log(this.idleState);
});

// sets the ping interval to 15 seconds
keepalive.interval(15);

keepalive.onPing.subscribe(() => this.lastPing = new Date());

this.preauth.getUserSessionActive().subscribe(userSessionActive => {
  if (userSessionActive) {
    idle.watch()
    this.timedOut = false;
  } else {
    idle.stop();
  }8
})

// this.reset();
}
  ngOnInit(): void {
    this.postauth.session_expired_subject.subscribe(res=>{
    if(res){
      //this.sessionModal.show();
      this.notiservice.notify('error', 'Your session has expired!');
      this.router.navigate(['/guest/lock']);   
    }

    })
  }

reset() {
this.idle.watch();
//xthis.idleState = 'Started.';
this.timedOut = false;
}


hideChildModal(): void {
this.childModal.hide();
}

stay() {
this.childModal.hide();
this.reset();
}

logout() {
this.childModal.hide();
this.sessionModal.hide();
this.preauth.setUserSessionActive(false);
this.router.navigate(['/']);
}
relogin(){
  this.sessionModal.hide();
  this.preauth.setUserSessionActive(false);
  this.router.navigate(['/guest/lock']);
}
}