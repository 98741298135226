import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { element } from 'protractor';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
import { PostauthService } from '../services/postauth.service';
import { PreAuthService } from '../services/pre-auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticatedGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(private postauth: PostauthService, private preauth: PreAuthService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isauthenticated(state.url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isauthenticated(state.url);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    return this.isauthenticated(route.path);
  }

  isauthenticated(url: string): boolean {

   

    if (this.postauth.currentUser === undefined) {
      var userfromStorage= JSON.parse(localStorage.getItem('User'));
      var DatafromStorage= JSON.parse(localStorage.getItem('Data'));
      
  
      

      this.postauth.currentUser = userfromStorage[0];
      this.postauth.data_on_localstorage=DatafromStorage[0];      
      this.postauth.assigned_data = this.postauth.data_on_localstorage;
      if (userfromStorage !== null) {
           userfromStorage.forEach(element => {
          this.postauth.currentUser.session = element['session']
          // console.log("my alert>>"+ element['session'] )
          this.postauth.currentUser.authenticated=true;
        })
      } else {
       
        this.router.navigate(['/guest']);
        return false;
      }
    }
    if (this.postauth!== undefined &&this.postauth.currentUser !== undefined && this.postauth.currentUser.authenticated === true) { return true; } else {


      this.router.navigate(['/guest']);
      return false;
    }
  }
}
