import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { ReportSidebarComponent } from './components/report-sidebar/report-sidebar.component';
import { SalesDashBoardComponent } from './components/sales-dash-board/sales-dash-board.component';
import { PricingDashBoardComponent } from './components/pricing-dash-board/pricing-dash-board.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BsDatepickerModule, BsDropdownModule, CollapseModule, ModalModule, ProgressbarModule, TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { NewReportroutes } from './newreport-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { TagInputModule } from 'ngx-chips';
import { NotifierModule } from 'angular-notifier';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule, MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { GoogleheatmapComponent } from './components/googleheatmap/googleheatmap.component';

import { ReportNavbarComponent } from './components/report-navbar/report-navbar.component';

import { SortByPipe } from 'src/app/_mypipes/sort-by.pipe';
import { GroupedComponent } from './components/grouped/grouped.component';


@NgModule({
  declarations: [ReportSidebarComponent,PricingDashBoardComponent,
    ReportNavbarComponent, ReportSidebarComponent,
    SalesDashBoardComponent, GroupedComponent, GoogleheatmapComponent,SortByPipe],
  imports: [
    CollapseModule.forRoot(),
    RouterModule.forChild(NewReportroutes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
        TagInputModule,
    AngularMultiSelectModule,
    // ArchwizardModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ComponentsModule,
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ProgressbarModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    ChartModule,
    MatButtonToggleModule,NotifierModule,MatButtonModule,MatIconModule,MatRippleModule,
     MatFormFieldModule, MatInputModule, MatNativeDateModule,NgxMatSelectSearchModule,
    MatSelectModule, MatTooltipModule, MatDatepickerModule,
    FormsModule,    
    ReactiveFormsModule,
    
 

  ],
  providers:[TitleCasePipe],
  exports:[ReportSidebarComponent]
})
export class NewreportModule { }
