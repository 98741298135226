import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appKeydownHostListener]'
})
export class KeydownHostListenerDirective {

  constructor() { }

   // trigger an additional change detection cycle
   @HostListener('keydown') onKeydownHandler() {
    setTimeout(()=>{});
  }

}
