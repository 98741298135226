import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsListResolver } from 'src/app/products/edit-products/edit-products.resolver';
import { DiscountsComponent  } from './components/fixed-discounts/discounts.component';

import { DiscountListResolver } from './resolvers/discount-list.resolver';

const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    children: [

      {
        path: 'discounts',
        component: DiscountsComponent,
        runGuardsAndResolvers: 'always',
        resolve:{products:ProductsListResolver,discounts:DiscountListResolver}
   
      },
     


    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromotionRoutingModule { }
