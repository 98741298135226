
import { PostauthService } from './../../services/postauth.service';
import { Injectable } from '@angular/core';
import { Resolve,ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute  } from '@angular/router';
import { Observable } from 'rxjs';
import { Requestembedtoken } from '../dashboardpowerbi/dashboardpowerbi.component';

@Injectable({
  providedIn: 'root'
})
export class PowerbiresolverService implements Resolve<any> {
  

  constructor(public postauth:PostauthService, private actRoute: ActivatedRoute) { 

        }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  {
let embedReportdetail: Requestembedtoken =  {reportid:route.params.reportId,datasetid:route.params.datasetId} 
console.log('multiple bi reports')
console.log(embedReportdetail);
return  this.postauth.updatePowerBiReportconfigs(embedReportdetail)

  }
}
