import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule, DatePipe } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { ReportLayoutComponent } from "./components/report-layout/report-layout/report-layout.component";
import { HttpClientModule, HTTP_INTERCEPTORS  } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { ChartModule } from "angular-highcharts";
import { NotifierModule } from "angular-notifier";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPaginationModule } from "ngx-pagination";
import { AdminLayoutModule } from "./layouts/admin-layout/admin-layout.module";
import { SortByPipe } from "./layouts/mypipes/sort-by.pipe";
import { MatDialogModule } from "@angular/material/dialog";
import { NewreportModule } from "./modules/post_auth/newreport/newreport.module";
import { FooterComponent } from "./components/footer/footer.component";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NotifyInterceptor } from './Interceptor/notify';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeydownHostListenerDirective } from './directives/keydown-host-listener.directive';
import { DataTablesModule } from "angular-datatables";
import { PromotionModule } from "./modules/promotion/promotion.module";
import { FulfillmentPartnerComponent } from './manufacturer-distribution/fulfillment-partner/fulfillment-partner.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
// import {MatChipsModule} from '@angular/material/chips'
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { LoginInterceptor } from "./Interceptor/login.interceptor";

//import { NgxSpinnerService } from "ngx-spinner";
//import { NgSelectModule } from '@ng-select/ng-select';
//import { SalesModule } from "./sales/sales.module";
 
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    RtlLayoutComponent,
    ReportLayoutComponent,
    AdminLayoutComponent,
    SortByPipe,
    FooterComponent,
    KeydownHostListenerDirective,
    
 
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule,
    ComponentsModule,  
    FormsModule,
    ReactiveFormsModule,
    AdminLayoutModule,
    HttpClientModule,
    ChartModule,
    NotifierModule,
    ComponentsModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    BrowserModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    AppRoutingModule,
    MatDialogModule,
    NewreportModule,
    NgbModule,
    ModalModule,
    DataTablesModule,
    //NgxSpinnerService,
    //NgSelectModule,
    //SalesModule,
    PromotionModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
   
  ],
  exports:[],

  providers: [DatePipe, 
    // {provide: HTTP_INTERCEPTORS, useClass: NotifyInterceptor, multi: true}
    {provide:HTTP_INTERCEPTORS,useClass:LoginInterceptor,multi:true},
    BsModalService
  ],
  bootstrap: [AppComponent]

})
export class AppModule {}
