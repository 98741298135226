import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { manufacturerProduct } from 'src/app/layouts/otmdashboard/dashboard.component';
import { PostauthService } from 'src/app/services/postauth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsListResolver implements Resolve<manufacturerProduct[]> {
  constructor(private postauth:PostauthService){

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<manufacturerProduct[]> |Promise <manufacturerProduct[]>| manufacturerProduct[]{
    return this.postauth.getManProducts("get_products");
  }
}
