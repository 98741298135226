import { LogutGuardGuard } from './guards/logut-guard.guard';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { ReportLayoutComponent } from "./components/report-layout/report-layout/report-layout.component";

const routes: Routes = [

  /**OTM ROUTES */
  {
    path: '',
    redirectTo: 'guest',
    pathMatch: 'full',
  },
  {
    path: 'guest',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: './modules/preauth/preauth.module#PreauthModule'

    }]
  },
  {
    path: 'logout',
    component: AuthLayoutComponent,
    canActivate: [LogutGuardGuard],
    canLoad: [LogutGuardGuard],
    children: [{
      path: '',
      loadChildren: './modules/preauth/preauth.module#PreauthModule'

    }]
    // redirectTo: 'guest/login',
    // pathMatch: 'full',
  },
  {
    path: 'home',
    component: AdminLayoutComponent,
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
    children: [{
      path: '',
      loadChildren: "./layouts/admin-layout/admin-layout.module#AdminLayoutModule"

    }]
  },
  {
    path: 'pages',
    children: [{
      path: '',
      loadChildren: "./pages/examples/pages/pages.module#PagesModule"
    },
    {
      path: '',

      loadChildren:
        "./pages/examples/pages/timeline/timeline.module#TimelineModule"
    },
    {
      path: '',
      loadChildren:
        "./pages/examples/pages/user/user-profile.module#UserModule"
    }]
  },

  {
    path: "dashboard",
    component: AdminLayoutComponent,
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/otmdashboard/dashboard.module#DashboardModule"
      },
    ]
  },
  {
    path: 'reports',
    component: ReportLayoutComponent,
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
    children: [{
      path: '',
      loadChildren: "./modules/post_auth/newreport/newreport.module#NewreportModule"

    }],
    runGuardsAndResolvers: "always"
  }, {
    path: "promotions",
    component: AdminLayoutComponent,
    canActivate: [AuthenticatedGuard],
    canLoad: [AuthenticatedGuard],
    children: [
      {
        path: "",
        loadChildren:
          "./modules/promotion/promotion.module#PromotionModule"
      },
    ]
  }
  ,



  {
    path: "components",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./pages/examples/components/components.module#ComponentsPageModule"
      }]
  },

  {
    path: "forms",
    component: AdminLayoutComponent,
    loadChildren: "./pages/examples/forms/forms.module#Forms"
  },
  {
    path: "tables",
    component: AdminLayoutComponent,
    loadChildren: "./pages/examples/tables/tables.module#TablesModule"
  },
  {
    path: "maps",
    component: AdminLayoutComponent,
    loadChildren: "./pages/examples/maps/maps.module#MapsModule"
  },
  {
    path: "widgets",
    component: AdminLayoutComponent,
    loadChildren: "./pages/examples/widgets/widgets.module#WidgetsModule"
  },
  {
    path: "charts",
    component: AdminLayoutComponent,
    loadChildren: "./pages/examples/charts/charts.module#ChartsModule"
  },
  {
    path: "calendar",
    component: AdminLayoutComponent,
    loadChildren:
      "./pages/examples/calendar/calendar.module#CalendarModulee"
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/examples/pages/pages.module#PagesModule"
      }
    ]
  },
  {
    path: "",
    component: RtlLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/examples/pages/rtl/rtl.module#RtlModule"
      }
    ]
  },



  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
]






@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64],
      enableTracing: true,
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
